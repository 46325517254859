import Vue from 'vue'
import App from './App.vue'
import router from '@/router/index.js'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from "axios";
import VueCookies from "vue-cookies";
import md5 from "js-md5";
import modal from '@/plugins/modal.js';
import {
    VueJsonp
} from 'vue-jsonp'
import {
    resolvewin
} from "./assets/js/index";
// 抽屉组件二次封装
import BaseDrawer from "@/components/BaseDrawer/BaseDrawer.vue";

Vue.component("BaseDrawer", BaseDrawer);
Vue.use(VueJsonp);
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.prototype.$axios = axios;
Vue.prototype.$cookie = VueCookies; //cookie
Vue.prototype.resolvewin = resolvewin; //页面跳转
Vue.prototype.$md5 = md5;
import utils from './assets/js/utils'
Vue.prototype.utils = utils;
    // 模态框对象
Vue.prototype.$modal = modal
import {
    showLoading,
    hideLoading,
    showLoading1,
    hideLoading1,
    showLoading2,
    hideLoading2
} from "../src/assets/js/loading";

import {
    Notification
} from "element-ui";


export const preventReClick = Vue.directive("preventReClick", {
    inserted: function(el, binding) {
        el.addEventListener("click", () => {
            if (!el.disabled) {
                var elHtml = el.innerHTML; //保存原始html标签
                var elClassName = el.className; //保存原始class
                var saveHtml = "";
                for (var i = 0; i < el.children.length; i++) {
                    if (el.children[i].className.indexOf("iconfont") == -1) {
                        saveHtml = el.children[i].innerHTML; //取出按钮文字
                    }
                }
                if (el.className.indexOf("el-button--text") < 0) {
                    el.innerHTML =
                        "<i class='el-icon-loading'></i>" + "<span>" + saveHtml + "</span>";
                    el.className += " is-loading";
                }
                el.disabled = true;
                el.style = "opacity:0.8";
                setTimeout(() => {
                    el.disabled = false;
                    el.style = "opacity:1";
                    el.innerHTML = elHtml;
                    el.className = elClassName;
                }, binding.value || 2000);
            }
        });
    }
});
/**
 * 请求拦截器
 */
var showLoadType = "1";
axios.interceptors.request.use(
    config => {
        // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
        // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
        //const token = $cookies.get("AccessToken");
        //token && (config.headers.Authorization = 'bearer ' + token);
        // return config;
        // loading加载动画
        if (config.headers.showLoadType == "2") {
            showLoading1();
            showLoadType = "2";
        } else if (
            config.headers.showLoadType &&
            config.headers.showLoadType != "" &&
            config.headers.showLoadType != "2" &&
            config.headers.showLoadType != "none"
        ) {
            showLoading2(config.headers.showLoadType);
            showLoadType = "3";
        } else if (config.headers.showLoadType === "all") {
            showLoading();
            showLoadType = "1";
        }
        const token = localStorage.getItem('token'); // $cookies.get("AccessToken");
        if (token != null) {
            config.headers.token = token;
        }
		config.headers["Content-Type"] = "application/json";
        config.headers.language = "zh-CN";
        return config;
    },
    error => {
        return Promise.error(error);
    }
);
// axios 拦截处理响应
axios.interceptors.response.use(
    function(response) {
        if (showLoadType === "2") {
            hideLoading1();
        } else if (showLoadType === "3") {
            hideLoading2();
        } else if (showLoadType === "1") {
            hideLoading();
        }
        if (response.data.errorCode === 400) {
            localStorage.removeItem("token");
            localStorage.removeItem("userinfo");
            resolvewin('/login', {}, 1)
        }
        return response.data;
    },
    function(error) {
        if (showLoadType === "2") {
            hideLoading1();
        } else if (showLoadType === "3") {
            hideLoading2();
        } else if (showLoadType === "1") {
            hideLoading();
        }
        // 对响应错误做点什么
        if (error && error.status) {
            switch (error.status) {
                case 400:
                    error.message = "错误请求";
                    break;
                case 401:
                    error.message = "登录已失效，请重新登录";
                    break;
                case 402:
                    error.message = "权限不足";
                    break;
                case 403:
                    error.message = "拒绝访问";
                    break;
                case 404:
                    error.message = "请求失败，请联系管理员";
                    break;
                case 405:
                    error.message = "请求方法未允许";
                    break;
                case 408:
                    error.message = "请求超时";
                    break;
                case 500:
                    error.message = "当前操作系统异常，请联系管理员";
                    break;
                case 501:
                    error.message = "网络未实现";
                    break;
                case 502:
                    error.message = "网络错误";
                    break;
                case 503:
                    error.message = "服务不可用";
                    break;
                case 504:
                    error.message = "网络超时";
                    break;
                case 505:
                    error.message = "http版本不支持该请求";
                    break;
                default:
                    error.message = `连接错误${error.status}`;
            }
            let errorData = {
                status: error.status,
                message: error.message,
                config: error.config
            };
            Notification({
                title: "出错了",
                type: "error",
                message: errorData.message
            });
            return Promise.reject(error);
        } else {
            Notification({
                title: "出错了",
                type: "error",
                message: '当前操作系统异常，请联系管理员'
            });
        }
        return Promise.reject(error);
    }
);
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')