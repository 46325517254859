import {
    Loading
} from 'element-ui';

let loadingCount = 0;
let loading;
let loadingCount1 = 0;
let loading1;
let loadingCount2 = 0;
let loading2;
let timer = "";
let Progress = 0;
let initTime = 300;
const startLoading = () => {
    loading = Loading.service({
        lock: true,
        //   target:".el-table__body",
        text: 'loading...', //可以自定义文字
        spinner: 'el-icon-loading', //自定义加载图标类名
        // background: 'rgba(0, 0, 0, 0.7)' //遮罩层背景色
    });
};
const startLoading1 = (datas) => {
    var datas = "正在准备资料中,请耐心等待";
    getProgress();
    loading1 = Loading.service({
        lock: true,
        //  target: datas,
        text: datas, //可以自定义文字
        spinner: 'el-icon-loading', //自定义加载图标类名
        // background: 'rgba(0, 0, 0, 0.7)' //遮罩层背景色
    });
};
const startLoading2 = (datas) => {
    loading2 = Loading.service({
        lock: true,
        target: datas,
        text: 'loading...', //可以自定义文字
        spinner: 'el-icon-loading', //自定义加载图标类名
        // background: 'rgba(0, 0, 0, 0.7)' //遮罩层背景色
    });
};
const getProgress = () => {
    timer = setInterval(() => {
        Progress = Progress + 1;
        if (Progress > 6) {
            Progress = 0;
        }
        var datatxt = "";
        for (let index = 0; index < Progress; index++) {
            datatxt = datatxt + ".";
        }
        loading1.text = "正在准备资料中,请耐心等待" + datatxt;
    }, initTime);
};
const endLoading = () => {
    loading.close();
};
const endLoading1 = () => {
    clearInterval(timer);
    loading1.close();
};
const endLoading2 = () => {
    loading2.close();
};
export const showLoading = () => {
    if (loadingCount === 0) {
        startLoading();
    }
    loadingCount += 1;
};
export const hideLoading = () => {
    if (loadingCount <= 0) {
        return;
    }
    loadingCount -= 1;
    if (loadingCount === 0) {
        endLoading();
    }
};
export const showLoading1 = () => {
    if (loadingCount === 0) {
        // timer = setInterval(getProgress, 500);
        startLoading1();
    }
    loadingCount1 += 1;
};
export const hideLoading1 = () => {
    if (loadingCount1 <= 0) {
        return;
    }
    loadingCount1 -= 1;
    if (loadingCount1 === 0) {
        clearInterval(timer);
        endLoading1();
    }
};
export const showLoading2 = (datas) => {
    if (loadingCount2 === 0) {
        startLoading2(datas);
    }
    loadingCount2 += 1;
};
export const hideLoading2 = () => {
    if (loadingCount2 <= 0) {
        return;
    }
    loadingCount2 -= 1;
    if (loadingCount2 === 0) {
        endLoading2();
    }
};