<template>
	<div class="home">
		<div class="home-contain">
			<div class="max-box">
				<div class="top-menu-box">
					<a class="top">
						<div class="center-box">
							<span class="domain-name">本站官方唯一域名：www.lhrc.com.cn</span>
							<!--<el-dropdown>
								 <span class="el-dropdown-link">
									<span class="domain-url">【局网站群】</span>
								</span> 
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item><span
											@click="openNewPage('http://www.lhhrss.gov.cn')">临海市人力资源和社会保障局</span>
									</el-dropdown-item>
									<el-dropdown-item><span
											@click="openNewPage('http://www.lhrc.com.cn')">中国临海人才官方网站</span>
									</el-dropdown-item>
									<el-dropdown-item><span
											@click="openNewPage('http://linhai.lhrc.com.cn')">走进临海(人才市场办宣传临海)</span>
									</el-dropdown-item>
									<el-dropdown-item><span
											@click="openNewPage('http://gov.lhrc.com.cn')">临海市人才工作服务网</span>
									</el-dropdown-item>
									<el-dropdown-item><span
											@click="openNewPage('http://www.lhrlzyw.com')">临海人力资源网</span>
									</el-dropdown-item>
									<el-dropdown-item><span
											@click="openNewPage('http://www.lhsb.gov.cn')">临海市社会保障局</span>
									</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>-->
							<ul>
								<li @click="changeTab(item.url)" v-for="item in menu_top_list" :key="item.id">
									{{item.name}}
								</li>
							</ul>
							<div class="sign-div" v-if="userinfo === null">
								<div class="item-div" @click="changeTab('/login')">
									<img :src="require('@/assets/img/home/icon_qy.png')">
									<span>个人登录</span>
								</div>
								<div class="item-div" @click="changeTab('/loginc')">
									<img :src="require('@/assets/img/home/icon_user.png')">
									<span>企业登录</span>
								</div>
							</div>
							<div class="user-nav user-nav-item1"
								v-if="userinfo != null&&userinfo.userType + '' === '1'">
								<span class="user-name" :title="userinfo.peR_NAME
							                    ? userinfo.peR_NAME
							                    : userinfo.peR_ACCOUNT">【{{userinfo.peR_NAME
							                    ? userinfo.peR_NAME
							                    : userinfo.peR_ACCOUNT}}】</span>
								<router-link to="usercenter?type=0"><span class="blue">[个人中心]</span>
								</router-link>
								<el-dropdown @command="handleCommand">
									<img :src="setcompanylogo(userinfo.peR_IMG_PATH,'tx')" alt="" />
									<el-dropdown-menu slot="dropdown">
										<el-dropdown-item command='loingout'>退出</el-dropdown-item>
									</el-dropdown-menu>
								</el-dropdown>
							</div>
							<div class="user-nav user-nav-item1"
								v-if="userinfo != null&&userinfo.userType + '' === '2'">
								<span class="user-name" :title="userinfo.coM_NAME
							                  ? userinfo.coM_NAME
							                  : userinfo.coM_ACCOUNT
							              ">【{{
							                userinfo.coM_NAME
							                  ? userinfo.coM_NAME
							                  : userinfo.coM_ACCOUNT
							              }}】</span>
								<router-link to="companycenter?type=0"><span class="blue">[企业中心]</span>
								</router-link>
								<el-dropdown @command="handleCommand">
									<img :src="setcompanylogo(userinfo.coM_LOGO,'tx')" alt="" />
									<el-dropdown-menu slot="dropdown">
										<el-dropdown-item command='loingout'>退出</el-dropdown-item>
									</el-dropdown-menu>
								</el-dropdown>
							</div>
						</div>
					</a>
				</div>

				<div class="home-search">
					<div class="left-box">
						<img :src="require('@/assets/img/home/logo.png')" class="logo">
						<div class="select-contain">
							<el-form>
								<el-form-item>
									<el-col :span="24" style="display:flex;">
										<el-col style="width:120px">
											<el-dropdown split-button type="primary" @command="handleCommand">
												{{ searchType }}
												<el-dropdown-menu slot="dropdown">
													<el-dropdown-item command="0">职位</el-dropdown-item>
													<el-dropdown-item command="1">公司</el-dropdown-item>
												</el-dropdown-menu>
											</el-dropdown>
										</el-col>
										<el-input v-model="input" placeholder="搜索职位、公司"></el-input>
									</el-col>
									<el-col :span="4">
										<el-button type="primary" @click="seljob">搜索</el-button>
									</el-col>
								</el-form-item>
							</el-form>
						</div>

					</div>
					<div class="right-box">
						<!-- <div class="title">机遇事业前程 尽在临海人才</div> -->
						<img :src="require('@/assets/img/home/text.png')" class="text-img">
						<div class="list">
							<div class="list-div" @mousemove="mouseOverCard(0)" @mouseout="mouseOutCard(0)">
								<div class="picbox">
									<img :src="require('@/assets/img/home/icon_wx.png')">
								</div>
								<div>
									<div class="name">关注公众号</div>
									<div class="contant">掌握最新求职动态</div>
								</div>
							</div>
							<div class="list-div" @mousemove="mouseOverCard(1)" @mouseout="mouseOutCard(1)">
								<div class="picbox">
									<img :src="require('@/assets/img/home/icon_xcx.png')">
								</div>
								<div>
									<div class="name">小程序</div>
									<div class="contant">随时随地找工作</div>
								</div>
							</div>
							<div class="card-box card-box1" v-if="Card1Show">
								<img :src="require('@/assets/img/home/card1.jpg')">
							</div>
							<div class="card-box card-box2" v-if="Card2Show">
								<img :src="require('@/assets/img/home/card2.jpg')">
							</div>
						</div>
					</div>

				</div>
				<div class="NavMenu-box">
					<div class="center-box">
						<div class="NavMenu-div">
							<img :src="require('@/assets/img/home/icon_menu.png')" class="icon">
							<div>导航栏</div>
							<img :src="require('@/assets/img/home/arrow.png')" class="arrow">
						</div>
						<ul class="menu-ul">
							<li @click="changeTab(item.url)" v-for="item in $store.state.navList" :key="item.id">
								{{item.name}}
							</li>
						</ul>
					</div>

				</div>
			</div>
		</div>

		<div class="swiper-box">
			<div class="title_left" @mouseleave="mouseout()">
				<div class="el-menu">
					<div v-for="(item, index) in hylist" :key="index" class="menu-div"
						@mouseenter="mouseover(item.auto_id,item.com_account)"
						@click="goto('jobhunting',{type:item.auto_id},2)">
						<img :src="require('@/assets/img/home/icon_blue.png')" class="icon">
						<span class="title">{{item.com_account}}</span>
						<i class="el-icon-arrow-right"></i>
					</div>
					<div class="menu-div menu-all" @click="changeTab('/jobhunting')">
						<img :src="require('@/assets/img/home/icon_all.png')" class="icon">
						<span class="title">全部职位</span>
						<i class="el-icon-arrow-down"></i>
					</div>
				</div>
				<div v-if="submenushow" class="el-submenu">
					<div class="subtitle">{{com_account}}</div>
					<a v-for="(item, index) in hylist1" :key="index"
						@click="goto('jobhunting',{type:auto_id,type1:item.auto_id},2)">
						{{item.com_account}}
					</a>
				</div>
			</div>
			<div class="title_center">
				<el-carousel :interval="5000" height="260px" indicator-position="none" class="carousel-box">
					<el-carousel-item v-for="item in carousellist" :key="item.id"
						style="display: flex; justify-content: center">
						<el-image fit="fill" style="border-radius: 6px;height:100%" :src="defimgurl + item.picName"
							@click="changeTab(item.url)">
						</el-image>
					</el-carousel-item>
				</el-carousel>
			</div>
			<div class="title_right">
				<div class="form-box" v-if="userinfo === null">
					<ul>
						<li @click="change(0)" :class="[index == 0 ? 'select-title':'']">企业登录</li>
						<li @click="change(1)" :class="[index == 1 ? 'select-title':'']">个人登录</li>
					</ul>
					<div class="input-box">
						<el-input v-model="subfrom.mobile" placeholder="账号"></el-input>
					</div>
					<div class="input-box">
						<el-input v-model="subfrom.password" placeholder="密码" show-password></el-input>
					</div>
					<div class="input-box">
						<el-input v-model="subfrom.code" placeholder="验证码"></el-input>
						<div class="verification" id="canvasCode"></div>
					</div>
					<div class="btn-box" v-if="index == 0">
						<button class="signbtn" @click="logininfo">企业登录</button>
						<button class="registerbtn" @click="changeTab('/registerc')">企业注册</button>
					</div>
					<div class="btn-box" v-else>
						<div class="sign-personal" @click="logininfo">登录</div>
					</div>
					<el-checkbox v-model="checked"></el-checkbox>
					<span class="checkbox-text">同意<a> 用户服务协议 </a>和<a> 隐私政策 </a></span>
				</div>
				<div class="form-box personal-box" v-if="userinfo != null && userType == 1">
					<div>
						<div class="head-box">
							<img :src="setcompanylogo(userinfo.peR_IMG_PATH,'home/head')">
							<div class="name-box">
								<div class="name">您好！{{userinfo.peR_NAME}}</div>
								<div class="contant">欢迎回来</div>
							</div>
						</div>
						<div class="list-div">
							<div class="item-div" @click="goto('usercenter',{type:2},1)">
								<div class="item-num blue">{{num1}}</div>
								<div class="item-name">已投递职位</div>
							</div>
							<div class="item-div" @click="goto('usercenter',{type:3},1)">
								<div class="item-num red">{{num2}}</div>
								<div class="item-name">已收藏职位</div>
							</div>
						</div>
						<div class="look-resume" @click="isRshow=!isRshow;uid=userinfo.peR_ID;isCall=1">在线简历</div>
						<!-- <div class="resume-problem">
							<span>您有2个简历问题待处理</span>
							<i class="el-icon-arrow-right"></i>
						</div> -->
					</div>
				</div>
				<div class="form-box personal-box enterprise-box" v-if="userinfo != null && userType == 2">
					<div>
						<div class="head-box">
							<img :src="setcompanylogo(userinfo.coM_LOGO,'home/head')">
							<div class="name-box">
								<div class="name">您好！{{userinfo.coM_NAME}}</div>
								<div class="contant">欢迎回来</div>
							</div>
						</div>
						<div class="list-div">
							<div class="item-div" @click="goto('companycenter',{type:1},1)">
								<div class="item-num blue">{{num2}}</div>
								<div class="item-name">查看简历</div>
							</div>
							<div class="item-div" @click="goto('companycenter',{type:3},1)">
								<div class="item-num red">{{num1}}</div>
								<div class="item-name">在招职位</div>
							</div>
						</div>
						<div class="look-resume" @click="goto('companycenter',{type:3},1)">发布职位</div>
						<div class="search-resume" @click="goto('companycenter',{type:8},1)">搜索简历</div>
					</div>
				</div>
			</div>
		</div>

		<div class="message-box">
			<ul>
				<li>
					<div class="top-box">
						<div class="left">
							<img :src="require('@/assets/img/home/title_icon.png')" class="icon">
							<span>本地动态</span>
							<div class="border"></div>
						</div>
						<div class="right" @click="newschage('39')">
							<span>更多</span>
							<i class="el-icon-arrow-right"></i>
						</div>
					</div>
					<div class="bottom-box">
						<div v-for="item in news_list2" :key="item.id" @click="goto('/info', { id: item.id })" :class="['items',
                  item.cRED == 1 ? 'red' : '',
                ]">
							<div class="titles">
								<i></i>
								<img :src="require('@/assets/img/new.png')"
									v-if="utils.cd(new Date(),item.dUpdateDate,'d')<=3">
								<img :src="require('@/assets/img/hot.png')" v-if="item.cWEIGHT == 1">
								{{item.cTITLE}}
							</div>
							<div class="date">{{stringToDates(item.dREDATE)}}</div>
						</div>
					</div>
				</li>
				<li>
					<div class="top-box">
						<div class="left">
							<img :src="require('@/assets/img/home/title_icon.png')" class="icon">
							<span>热点招考</span>
							<div class="border"></div>
						</div>
						<div class="right" @click="newschage('79')">
							<span>更多</span>
							<i class="el-icon-arrow-right"></i>
						</div>
					</div>
					<div class="bottom-box">
						<div v-for="item in news_list3" :key="item.id" @click="goto('/info', { id: item.id })" :class="['items',
                  item.cRED == 1 ? 'red' : '',
                ]">
							<div class="titles">
								<i></i>
								<img :src="require('@/assets/img/new.png')"
									v-if="utils.cd(new Date(),item.dUpdateDate,'d')<=3">
								<img :src="require('@/assets/img/hot.png')" v-if="item.cWEIGHT == 1">
								{{item.cTITLE}}
							</div>
							<div class="date">{{stringToDates(item.dREDATE)}}</div>
						</div>
					</div>
				</li>
				<li>
					<div class="top-box">
						<div class="left">
							<img :src="require('@/assets/img/home/title_icon.png')" class="icon">
							<span>招聘会预告</span>
							<div class="border"></div>
						</div>
						<div class="right" @click="goto('jobfair')">
							<span>更多</span>
							<i class="el-icon-arrow-right"></i>
						</div>
					</div>
					<div class="bottom-box">
						<div v-for="item in news_list1" :key="item.id" @click="goto('/jobfairdetail', { id: item.id })"
							class="items">
							<div class="titles">
								<i></i>
								<img :src="require('@/assets/img/new.png')"
									v-if="utils.cd(new Date(),item.cDate,'d')<=3">{{item.cZph}}
							</div>
							<div class="date">{{stringToDates(item.cDate)}}</div>
						</div>
					</div>
				</li>
			</ul>
		</div>
		<div class="clear"></div>

		<div class="home-advertisement">
			<div v-for="item in bannerlist" :key="item.id">
				<img @click="linkto(item.url)" :src="defimgurl + item.picName" style="width:100%;cursor: pointer;"
					alt="" />
			</div>
		</div>

		<div class="big-title">名企招聘</div>
		<div class="home-enterprise">
			<ul>
				<li v-for="(item, index) in companydatas" :key="index" @click="linkUrl(item)">
					<img :src="defimgurl + item.linkPic" class="recruit-pic">
					<div class="company-box">{{linkName(item)}}</div>
				</li>
			</ul>
		</div>

		<div class="big-title">本季推荐</div>
		<div class="home-quarter">
			<ul class="home-quarter-ul">
				<li v-for="item in companydata" :key="item.id" @click="goto('/company', { id: item.coM_ID })">
					<div class="home-quarter-box">
						<img :src="setcompanylogo(item.coM_LOGO, 'deflogo')" alt="" class="company-logo">
						<div class="company-name">{{item.coM_NAME}}</div>
					</div>
					<div class="company-job">{{item.info}}</div>
				</li>
			</ul>
		</div>


		<div class="big-title">会员招聘</div>
		<div class="home-position">
			<div class="position-content">
				<div class="position-cell">
					<div class="position-item" v-for="item in positiondata" :key="item.oF_ID"
						@click="goto('/company', { id: item.coM_ID })">
						<div class="position-hd">
							<img :src="require('@/assets/img/xin/'+xin(item.ywcount1,item.ywcount)+'.png')"
								v-if="item.ywcount1+item.ywcount > 1" />
							{{ item.coM_NAME }}
						</div>
						<div class="position-bd">
							<div class="post-info">{{ item.info }}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="home-links">
			<div class="links-content">
				<div class="links-title">友情链接</div>
				<div class="links-cell">
					<div class="cell-item" v-for="(item, index) in linksdata" :key="index">
						<a @click="changeTab(item.url)">{{item.title}}</a>
					</div>
				</div>
			</div>
		</div>
		<resumedetail :isRshow="isRshow" :isCall="isCall" :uid="uid"></resumedetail>
		<righttool></righttool>
	</div>
</template>

<script>
	import resumedetail from "./resume/resumedetail.vue";
	import righttool from "../components/right-tool.vue";
	import {
		Getnewslist,
		Getadlist,
		Getadwordlinklist,
		Gettopcompanylist,
		Gethotcompanylist,
		Gethotposilist,
		Getrotationadvlist,
		GetAdvLinkByType,
		recPerson,
		getsearch,
		addsearch,
		GetHyList,
		getGVerify,
		GetAllHyList,
		Getywlist,
		Getjobfairlist
	} from "@/api/home";
	import utils from "@/assets/js/utils";
	import global from "@/assets/js/globalconfig";
	import {
		isnull
	} from "@/assets/js/index";
	import {
		getComNum
	} from "@/api/company";
	import {
		UserLogin,
		CompanyLogin,
		Getscanuserinfo,
		GetEnterpriseList,
		GetPerNum
	} from "@/api/user";
	export default {
		name: "Home",
		components: {
			resumedetail,
			righttool
		},
		data() {
			return {
				menu_top_list: [{
					name: '我要求职',
					url: "/jobhunting"
				}, {
					name: '我要招聘',
					url: "/resume"
				}, {
					name: '资讯中心',
					url: "/news"
				}, {
					name: '政策法规',
					url: "/news?id=46"
				}, {
					name: '下载中心',
					url: "/news?id=85"
				}, {
					name: '关于我们',
					url: "/talentService"
				}],
				index: 0,
				logtext: "企业",
				GVerifyCode: null,
				subfrom: {
					mobile: "",
					password: "",
				},
				timer: null,
				defimgurl: global.baseimgurl,
				searchid: 0,
				searchType: "职位",
				auto_id: '',
				com_account: '',
				hylist: [],
				hylist1: [],
				submenushow: false,
				checked: false,
				newslist: [],
				val: new Date(),
				companydatas: [],
				companydata: [],

				input: "",
				value: "",
				positiondata: [],
				linksdata: [],
				tabMain: [],
				cur: "",
				zpgyg: {},
				zpgyglist: [],


				bannerlist: [],

				userinfo: null,
				userType: 0,
				userid: 0,
				isCall: 0,
				isRshow: false,
				uid: 0,
				num1: 0,
				num2: 0,

				carousellist: [],
				homeimg: {},
				searchlist: [],
				selfrom: {
					pageNumber: 1,
					pageSize: 9
				},
				isback: false,
				news_list1: [],
				news_list2: [],
				news_list3: [],
				choice3: false,
				Card1Show: false,
				Card2Show: false,
				Card3Show: false,
				menuList: [{
						name: '首页',
						url: "/home"
					}, {
						name: '政企招聘',
						url: "/news?id=85"
					}, {
						name: '网络招聘',
						url: "/jobhunting"
					}, {
						name: '现场招聘',
						url: "/jobfair"
					}, {
						name: '校园招聘',
						url: "https://www.zjrc.com/active"
					},
					{
						name: '招考报名',
						url: "/zhaokaobm"
					}, {
						name: '人才档案',
						url: "/online"
					}, {
						// 	name: '政策',
						// 	url: ""
						// }, {
						name: '关于我们',
						url: "/talentService"
					}
				]
			};
		},
		created() {
			this.getDayAdv();
			//获取资讯信息
			this.Getrotationadvlists(); //轮播图
			this.getsearchlist()
			//this.Getnewslists('1', '36');
			this.GetList1();
			this.Getnewslists('2', '39');
			this.Getnewslists('3', '79');
			this.Getadlists();
			this.GetHyList();
			this.Gethotcompanylists(); //名企招聘
			this.Gettopcompanylists(); //本季招聘
			this.Gethotposilists(); //岗位推荐
			this.Getadlinklists()
		},
		mounted() {
			if (!isnull(localStorage.getItem("userinfo"))) {
				this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
				this.userType = this.userinfo.userType;
				if (this.userType == 1) {
					this.userid = this.userinfo.peR_ID;
					this.perNum(this.userinfo.peR_ID);
				} else {
					this.userid = this.userinfo.coM_ID;
					this.ComNum(this.userinfo.coM_ID);
				}
			} else
				this.getGVerify();
		},
		beforeDestroy() {
			// 销毁定时器，否则可能导致重载此组件时会有多个定时器同时执行，使得滚动变快
			window.clearTimeout(this.timer);
		},
		computed: {
			optionLeft() {
				return {
					step: 0,
					direction: 2, // 0向下 1向上 2向左 3向右
					hoverStop: true,
					openTouch: false,
				};
			},
		},
		methods: {

			GetList1() {
				var _this = this;
				_this.selfrom.pageNumber = 1;
				_this.selfrom.showLoadType = ".activity-cell";
				Getjobfairlist(_this.selfrom).then((res) => {
					if (res.success) {
						_this.news_list1 = res.data.rows;
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			//获取投递职位数和面试邀请数
			perNum(uid) {
				var _this = this;
				var p = {
					uid: uid
				}
				GetPerNum(p).then((res) => {
					if (res.success) {
						_this.num1 = res.data.num1;
						_this.num2 = res.data.num2;
					}
				});
			},
			ComNum(cid) {
				var _this = this;
				var p = {
					cid: cid
				}
				getComNum(p).then((res) => {
					if (res.success) {
						_this.num1 = res.data.num1;
						_this.num2 = res.data.num2;
					}
				});
			},
			xin(count1, count) {
				if (count1 >= 4) count1 = 4;
				return count1 + count;
			},
			linkUrl(item) {
				if (item.yW_TYPE == '2102') {
					this.goto("/company", {
						id: item.coM_ID
					}, 1);
				} else if (item.yW_TYPE == '2199') {
					this.goto("/jobfairdetail", {
						id: item.coM_ID
					}, 1);
				} else {
					this.goto(item.linkUrl, {}, 1);
				}
			},
			linkName(item) {
				if (item.yW_TYPE == '2102' && isnull(item.linkName)) {
					return item.com_name;
				} else if (item.yW_TYPE == '2199' && isnull(item.linkName)) {
					return item.zph_name;
				} else {
					return item.linkName;
				}
			},
			mouseOverCard(type) {
				if (type == 0) {
					this.Card1Show = true
				} else if (type == 1) {
					this.Card2Show = true
				} else {
					this.Card3Show = true
				}
			},
			mouseOutCard(type) {
				if (type == 0) {
					this.Card1Show = false
				} else if (type == 1) {
					this.Card2Show = false
				} else {
					this.Card3Show = false
				}
			},
			openNewPage(url) {
				window.open(url, '_blank')
			},
			change(index) {
				this.index = index;
				if (index == 0) {
					this.choice3 = false;
				} else {
					this.choice3 = true;
				}
			},
			changeTab(url) {
				//debugger
				if (url.indexOf("http") >= 0) {
					this.openNewPage(url)
				} else {
					this.resolvewin(url, {}, 2);
				}
			},
			logininfo() {
				var _this = this;
				if (isnull(_this.subfrom.mobile)) {
					_this.$message.error("请输入用户名");
					return false;
				}
				if (isnull(_this.subfrom.password)) {
					_this.$message.error("请输入密码");
					return false;
				}
				if (isnull(_this.subfrom.code)) {
					_this.$message.error("请输入验证码");
					return false;
				}
				if (_this.choice3) _this.userType = 1;
				else _this.userType = 2;
				var flag = this.GVerifyCode.validate(_this.subfrom.code);
				if (!flag) {
					this.$message({
						message: "验证码错误",
						type: "warning",
					});
					this.GVerifyCode.refresh();
					return false;
				}
				if (_this.choice3) {
					UserLogin(_this.subfrom).then((res) => {
						if (res.success) {
							this.$message({
								message: "登录成功",
								type: "success",
							});
							this.signLoading = false;
							var rdata = res.data;
							rdata.user.userType = 1;
							this.userinfo = rdata.user;
							localStorage.setItem("token", rdata.token);
							localStorage.setItem("userinfo", JSON.stringify(rdata.user));
							if (_this.isback) {
								setTimeout(_this.$router.go(-1), 500);
							} else {
								setTimeout(_this.resolvewin("/", {}, 1), 500);
							}
						} else {
							_this.$message.error(res.msg);
						}
					});
				} else {
					CompanyLogin(_this.subfrom).then((res) => {
						if (res.success) {
							this.$message({
								message: "登录成功",
								type: "success",
							});
							this.signLoading = false;
							var rdata = res.data;
							rdata.user.userType = 2;
							this.userinfo = rdata.user;
							localStorage.setItem("token", rdata.token);
							localStorage.setItem("userinfo", JSON.stringify(rdata.user));
							this.$forceUpdate()
							if (_this.cbxbox) {
								localStorage.setItem("mobile2", _this.subfrom.mobile);
								localStorage.setItem("password2", _this.subfrom.password);
							}
							if (_this.isback) {
								setTimeout(_this.$router.go(-1), 500);
							} else {
								setTimeout(_this.resolvewin("/", {}, 1), 500);
							}
						} else {
							_this.$message.error(res.msg);
						}
					});
				}
			},
			GetHyList() {
				var _this = this;
				GetHyList().then((res) => {
					if (res.success) {
						_this.hylist = res.data;
						var arr = []
						_this.hylist = _this.hylist.map((item, index) => {
							if (index < 5) {
								arr.push(item)
							}
						})
						_this.hylist = arr;
					}
				})
			},
			mouseover(type, com_account) {
				this.submenushow = true;
				this.auto_id = type;
				this.com_account = com_account;
				this.GetAllHyList(type);
			},
			mouseout() {
				this.submenushow = false;
			},
			GetAllHyList(type) {
				var _this = this;
				var par = {
					cid: type
				}
				GetAllHyList(par).then((res) => {
					if (res.success) {
						_this.hylist1 = res.data;
					}
				})
			},
			onChoice1(e) {
				this.logtext = "企业"
				this.choice1 = e
				this.choice2 = !e
				this.choice3 = false
			},
			getGVerify() {
				this.GVerifyCode = getGVerify({
					id: "canvasCode",
					width: "80",
					height: "30",
					fontSize: "26",
					numSize: 4,
					type: "blend",
				});
			},
			onChoice2(e) {
				this.logtext = "个人"
				this.choice2 = e
				this.choice1 = !e
				this.choice3 = true
			},
			handleOpen(key, keyPath) {
				console.log(key, keyPath);
			},
			handleClose(key, keyPath) {
				console.log(key, keyPath);
			},
			getDayAdv() {
				var _this = this;
				var par = {
					id: 18,
				};
				GetAdvLinkByType(par).then((res) => {
					if (res.success) {
						if (res.data.length > 0)
							_this.homeimg = res.data[0];
					}
				})
			},
			//首页轮播图
			Getrotationadvlists() {
				var _this = this;
				Getrotationadvlist().then((res) => {
					if (res.success) {
						_this.carousellist = res.data.rows;
					}
				});
			},
			stringToDates(data) {
				if (isnull(data)) {
					return "";
				}
				return utils.timeFormat(data, 4);
			},
			//获取资讯信息
			Getnewslists(type, id) {
				var _this = this;
				var par = {
					types: id,
					pageSize: 9,
				};
				Getnewslist(par).then((res) => {
					if (res.success) {
						if (type == '1')
							_this.news_list1 = res.data.rows;
						if (type == '2')
							_this.news_list2 = res.data.rows;
						if (type == '3')
							_this.news_list3 = res.data.rows;
					}
				});
			},
			newschage(id) {
				if (this.activeName == 0) {

				} else {
					localStorage.setItem("cTID", id);
				}
				this.goto("/news", {}, 1);
			},
			handleCommand(e) {
				if (e == 'loingout')
					this.loingout();
				else {
					this.searchid = e
					if (e == 1) {
						this.searchType = "公司";
					} else {
						this.searchType = "职位";
					}
				}
			},
			loingout() {
				var _this = this;
				_this
					.$confirm("确定退出吗?", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						localStorage.removeItem("token");
						localStorage.removeItem("userinfo");
						if (this.userinfo.userType != 1) {
							_this.resolvewin("/loginc", {
								type: 2
							}, 1);
						} else {
							_this.resolvewin("/login", {
								type: 2
							}, 1);
						}
						this.userinfo = null;
					})
					.catch(() => {});
			},
			seljob() {
				if (isnull(this.input)) {
					this.$message({
						message: "请输入职位/公司",
						type: "warning",
					});
					return false;
				}
				this.addsearch()
				if (this.searchid == 1) {
					this.resolvewin("/findEnterprise", {
						searchKey: this.input
					}, 1);
				} else {
					this.resolvewin("/jobhunting", {
						searchKey: this.input
					}, 1);
				}
			},
			handleClick(e) {
				if (this.activeName == 0) {
					this.$router.push("/news");
				}
			},

			handleClick1(title) {
				this.newstypeList2.forEach(item => {
					item.state = false
				})
				this.newstypeList2.forEach(item => {
					if (item.name == title.name) {
						item.state = true
					}
				})
				this.cur = title.id;
				this.GetrecPerson();
			},
			//广告banner
			Getadlists() {
				var _this = this;
				Getadlist().then((res) => {
					if (res.success) {
						_this.bannerlist = res.data.rows;
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			//友情链接
			Getadlinklists() {
				var _this = this;
				Getadwordlinklist().then((res) => {
					if (res.success) {
						_this.linksdata = res.data.rows;
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			Gettopcompanylists() {
				var _this = this;
				var par = {
					pageNumber: 1,
					pageSize: 10,
				};
				Gettopcompanylist(par).then((res) => {
					if (res.success) {
						_this.companydata = res.data.rows;
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			Gethotcompanylists() {
				var _this = this;
				var par = {
					pageNumber: 1,
					pageSize: 10,
				};
				Getywlist(par).then((res) => {
					if (res.success) {
						_this.companydatas = res.data;
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			analysisjob(datas) {
				var data = [];
				if (datas) {
					datas.split(",").forEach((item) => {
						if (item) {
							if (data.length < 3) {
								var a = item.split("|");
								if (a.length > 1) {
									var sp = a[1].split("-");
									data.push({
										postname: a[0],
										salary: this.setsalary(sp[0], sp[1]),
									});
								}
							}
						}
					});
				}
				return data;
			},
			setcompanylogo(url, data) {
				return isnull(url) ?
					require(`@/assets/img/${data}.png`) :
					this.defimgurl + url;
			},
			//岗位推荐
			Gethotposilists() {
				var _this = this;
				Gethotposilist({
					pageSize: 84
				}).then((res) => {
					if (res.success) {
						_this.positiondata = res.data.rows;
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			linkto(url) {
				this.resolvewin(url, {}, 3);
			},
			getsearchlist() {
				var _this = this;
				var per = {
					cid: 1,
					pageSize: 5
				}
				getsearch(per).then((res) => {
					if (res.success)
						this.searchlist = res.data
				})
			},
			addsearch() {
				var _this = this;
				var per = {
					idType: _this.userType,
					userId: _this.userid,
					searchType: _this.searchType == "职位" ? 1 : 2,
					searchKey: _this.input,
				}
				addsearch(per).then((res) => {})
			},
			goto(url, data, type = 2) {
				if (url != '')
					this.resolvewin(url, data, type);
			},
			changeTime(date, date1) {

			},
			ckislogin(url, data) {
				url = `/${this.userinfo.userType === 1 ? "usercenter" : "companycenter"
				}?type=0`;
				var par = {
					type: 0
				};
				if (isnull(localStorage.getItem("token"))) {
					par = data;
					url = "/login";
				}
				this.resolvewin(url, par, 1);
			},
			notice(data) {
				var zpgyglist = this.zpgyglist;
				zpgyglist.forEach((element) => {
					if (element.dYXQ) {
						var date = element.dYXQ.split(" ")[0];
						if (date == data.day) {
							data.isSelected = true;
						}
					}
				});

				return data;
			},
			setsalary(a, b) {
				return utils.setsalary(a, b);

			},
		},
	};
</script>
<style lang="scss" scoped>
	.fixedright {
		position: fixed;
		top: 260px;
		right: 10px;
	}

	.home-banner {
		width: 100%;
		height: 180px;
		margin: 0px;
		padding: 0px;
		position: relative;

		// background: url(../assets/img/banner.jpg) center top no-repeat;
		.banner-content {
			width: 1200px;
			margin: 0px auto;
			height: 180px;
			// background: url(../assets/img/banner.jpg) center top no-repeat;
			display: flex;
			justify-content: flex-end;
			align-items: center;

			.home-login-mode {
				height: 108px;
				border-radius: 8px;
				background: #fff;
				padding: 16px 24px;
				display: flex;
				align-items: center;

				.login-scancode {
					width: 96px;
					height: 108px;
					display: flex;
					flex-direction: column;

					img {
						width: 100%;
					}

					p {
						padding: 0;
						margin: 0;
						font-size: 14px;
						color: #333;
					}
				}

				.login-cell {
					flex: 1;
					width: 100%;
					display: flex;
					flex-direction: column;
					font-size: 12px;
					padding-left: 24px;
					margin-left: 24px;
					border-left: 1px solid #eee;

					.login-title {
						font-size: 14px;
						color: #333;
					}

					.login-item {
						cursor: pointer;
						margin-top: 10px;

						a {
							color: #787878;
							text-decoration: none;
							display: flex;
							align-items: center;

							i {
								font-size: 14px;
								width: 14px;
								text-align: left;
								margin-right: 5px;
							}
						}
					}
				}
			}
		}
	}

	.home {
		width: 100%;
		height: 100%;
		position: relative;
	}

	.home-contain {
		height: 100%;
	}

	.max-box {
		width: 100%;
		background: white;
	}

	.text-img {
		margin: 22px 0 0 0;
	}

	.top-menu-box {
		.top {
			text-decoration: none
		}

		background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(249, 249, 249, 1) 100%);
		text-align: center;
		border: 1px solid rgba(239, 239, 239, 1);
		height: 44px;
		line-height: 44px;

		.center-box {
			width: 1200px;
			margin: 0 auto;
			height: 100%;
			text-align: left;
			font-size: 14px;
			display: flex;
			align-items: center;
			position: relative;

			.domain-name {
				color: rgba(137, 137, 137, 1);
				cursor: pointer;
			}

			.domain-url {
				color: #1990FF;
				cursor: pointer;
			}

			ul {
				padding: 0;
				margin: 0;
				list-style: none;
				display: flex;
				margin-left: 220px;

				li {
					color: #898989;
					margin-left: 25px;
					cursor: pointer;
				}

				li:hover {
					color: #1990FF;
				}
			}

			.sign-div {
				display: flex;
				align-items: center;
				position: absolute;
				right: 0;

				.item-div {
					display: flex;
					align-items: center;
					margin-left: 25px;
					cursor: pointer;

					img {
						width: 20px;
						height: 20px;
						margin-right: 8px;
					}
				}
			}
		}
	}

	.personal-box {
		.head-box {
			display: flex;
			align-items: center;
			padding: 15px 0;

			img {
				width: 40px;
				height: 40px;
				border-radius: 50%;
				margin-right: 10px;
			}

			.name-box {
				flex: 1;

				.name {
					font-size: 16px;
					color: #101010;
                    height: 42px;
                    max-height: 42px;
                    overflow: hidden;
				}

				.contant {
					font-size: 14px;
					color: #898989;
					margin-top: 5px;
				}
			}
		}

		.list-div {
			margin-top: 10px;
			display: flex;

			.item-div {
				width: 50%;
				text-align: center;

				.item-name {
					font-size: 13px;
					margin-top: 10px;
				}

				.item-num {
					font-size: 18px;
				}

				.blue {
					color: #1990FF;
				}

				.red {
					color: #F70505;
				}
			}
		}

		.look-resume {
			height: 36px;
			line-height: 36px;
			border-radius: 4px;
			background-color: rgba(255, 255, 255, 1);
			color: rgba(25, 144, 255, 1);
			font-size: 14px;
			text-align: center;
			font-family: Microsoft Yahei;
			border: 1px solid rgba(25, 144, 255, 1);
			margin: 10px auto 10px;
			cursor: pointer;
		}

		.resume-problem {
			height: 36px;
			line-height: 36px;
			background-color: rgba(255, 228, 218, 1);
			text-align: center;
			color: #FD3636;
			font-size: 13px;
			text-align: left;
			padding: 0 12px;
			box-sizing: border-box;
			cursor: pointer;

			.el-icon-arrow-right {
				margin-left: 35px;
			}
		}

		.search-resume {
			height: 36px;
			line-height: 36px;
			border-radius: 4px;
			background-color: rgba(25, 144, 255, 1);
			color: rgba(255, 255, 255, 1);
			font-size: 14px;
			text-align: center;
			font-family: Microsoft Yahei;
			cursor: pointer;
		}
	}

	.NavMenu-box {
		width: 100%;
		height: 48px;
		border-bottom: 3px solid #1990FF;
		text-align: center;
		box-sizing: border-box;

		.center-box {
			width: 1200px;
			margin: 0 auto;

			display: flex;

			.NavMenu-div {
				display: flex;
				width: 260px;
				line-height: 48px;
				height: 48px;
				align-items: center;
				background-color: rgba(19, 57, 109, 1);
				color: white;
				font-size: 16px;
				padding: 0 15px;
				box-sizing: border-box;

				.icon {
					margin-right: 5px;
				}

				.arrow {
					margin-left: 125px;
				}
			}

			.menu-ul {
				flex: 1;
				padding: 0 20px;
				margin: 0;
				display: flex;

				li {
					line-height: 48px;
					height: 48px;
					margin-right: 60px;
					list-style: none;
					font-size: 16px;
					color: #000;
					cursor: pointer;
				}

				li:last-child {
					margin-right: 0;
				}

				li:hover {
					color: #0681fc;
				}
			}
		}
	}

	.home-search {
		width: 1200px;
		height: 180px;
		margin: 0 auto;
		display: flex;
		background: white;

		.left-box {
			width: 820px;
			height: 100%;

			.logo {
				width: 250px;
				height: 50px;
				margin-top: 20px;
			}

			.select-contain {
				width: 100%;
				margin: 26px 0;
				border: 2px solid rgba(25, 144, 255, 1);
				border-radius: 8px;
				box-sizing: border-box;
				overflow: hidden;

				/deep/.el-button--primary {
					color: #898989;
					background-color: #ffff;
					border-color: #ffff;

				}

				/deep/ .el-button-group {
					display: flex;
					align-items: center;
				}

				/deep/.el-dropdown .el-dropdown__caret-button::before {
					background: #fff;
				}

				.el-form {


					background: #fff;
					height: 48px;
					border-radius: 4px;


					// display: flex;
					/deep/.el-form-item__content {
						display: flex;
						background: #fff;
						border-radius: 0 4px 4px 0;
					}

					.el-form-item::v-deep {
						margin-bottom: 0;
					}

					.el-select ::v-deep {
						.el-input {


							.el-input__inner {
								border: none;
								border-radius: 0;
								font-size: 14px;
								line-height: 46px;
								height: 46px;

							}

							.el-input__inner::placeholder {
								color: #333;
							}
						}
					}

					.el-input ::v-deep {
						padding: 0;
						border: none;
						line-height: 46px;
						height: 46px;

						.el-input__inner {
							line-height: 48px;
							height: 48px;
							border: none;
							border-radius: 0;
							border-left: 1px solid #eee;
						}
					}



					.el-button {
						width: 139px;
						height: 48px;
						border-radius: 0px 8px 8px 0px;
						background-color: rgba(5, 142, 245, 1);
						color: rgba(255, 255, 255, 1);
						font-size: 18px;
						text-align: center;
						font-family: Microsoft Yahei;
					}
				}

				.resume {
					.el-button {
						width: 73%;
						height: 40px;
						// line-height: 40px;
						margin-left: 14px;
						border: 1px solid #F66308;
						color: #fff;
						text-align: center;
						border-radius: 4px;
						// padding-top: 16px;
						// padding-bottom: 10px;
						font-size: 16px;
						background: #F66308;

						/deep/span {
							display: flex;
							align-items: center;
						}
					}
				}

				.hot-word {
					text-align: left;
					font-size: 16px;
					color: #787878;

					a {
						padding: 0px 12px;
						color: #409eff;
						text-decoration: none;
					}
				}
			}
		}

		.right-box {
			text-align: center;
			flex: 1;
			height: 100%;

			.title {
				color: rgba(25, 144, 255, 1);
				font-size: 28px;
				text-align: right;
				font-family: PMZDCuShuSong-regular;
				margin: 30px auto 0;
			}

			.title-img {
				margin: 22px auto 0;
			}

			.list {
				padding: 40px 10px;
				box-sizing: border-box;
				width: 100%;
				display: flex;
				position: relative;

				.card-box {
					position: absolute;
					top: 70%;
					z-index: 1;
					width: 100px;
					height: 100px;

					img {
						width: 100%;
						height: 100%;
					}
				}

				.card-box1 {
					left: 19%;
				}

				.card-box2 {
					right: 10%;
				}

				.list-div {
					width: 50%;
					display: flex;
					text-align: left;
					padding-left: 30px;
					cursor: pointer;

					.name {
						font-size: 13px;
						color: #101010;
					}

					.contant {
						font-size: 13px;
						color: #898989;
					}

					.picbox {
						width: 36px;
						height: 36px;
						background-color: rgba(15, 207, 15, 1);
						text-align: center;
						border-radius: 50%;
						display: flex;
						align-items: center;
						text-align: center;
						margin-right: 10px;

						img {
							width: 24px;
							height: 24px;
							margin: 0 auto;
						}
					}

				}

				.list-div:last-child {
					.picbox {
						background: #1990FF;
					}
				}
			}
		}
	}

	.home-quarter {
		width: 1200px;
		margin: 0 auto;

		.home-quarter-ul {
			width: 100%;
			overflow: hidden;
			list-style: none;
			margin: 0;
			padding: 0;

			li {
				width: 183px;
				margin-right: 20px;
				margin-bottom: 20px;
				float: left;
				height: 145px;
				text-align: center;
				background: white;
				cursor: pointer;

				.home-quarter-box {
					height: 115px;

					.company-name {
						color: rgba(0, 137, 255, 1);
						font-size: 14px;
						text-align: center;
						font-family: SourceHanSansSC-regular;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						padding: 0 10px;
						box-sizing: border-box;

					}

					.company-logo {
						width: 150px;
						height: 55px;
						margin: 15px auto 11px;
					}
				}

				.company-job {
					height: 30px;
					line-height: 30px;
					background-color: rgba(238, 238, 238, 1);
					color: rgba(16, 16, 16, 1);
					font-size: 12px;
					text-align: center;
					font-family: Roboto;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					padding: 0 10px;
					box-sizing: border-box;
				}
			}

			li:nth-child(6n) {
				margin-right: 0 !important;
			}
		}
	}

	.swiper-box {
		position: relative;
		width: 1200px;
		height: 280px;
		margin: 0 auto;
		display: flex;

		.title_left {
			width: 260px;
			height: 100%;
			background: white;
			position: relative;
			border-radius: 0px 0px 8px 8px;

			.menu-div {
				width: 100%;
				display: flex;
				font-size: 15px;
				color: #101010;
				height: 25px;
				line-height: 25px;
				margin-top: 18px;
				align-items: center;
				position: relative;
				cursor: pointer;

				.icon {
					width: 18px;
					height: 18px;
					margin: 0 12px;
				}

				.el-icon-arrow-right {
					position: absolute;
					right: 10px;
					font-size: 18px;
					color: #058EF5;
				}
			}

			.menu-div:hover {
				color: #058EF5;
			}

			.menu-all {
				color: white;
				font-size: 15px;
				height: 48px;
				line-height: 48px;
				border-radius: 0px 0px 8px 8px;
				background-color: rgba(25, 144, 255, 1);

				.title {
					color: white;
				}

				.el-icon-arrow-down {
					position: absolute;
					right: 10px;
					font-size: 18px;
					color: white;
				}
			}

			.el-submenu {
				position: absolute;
				top: 0px;
				left: 100%;
				width: 100%;
				z-index: 996;
				background-color: #fff;
				font-size: 14px;
				padding: 10px;

				.subtitle {
					font-size: 16px;
					color: rgba(16, 16, 16, 100);
					font-family: SourceHanSansSC-regular;
					margin-bottom: 15px;
					font-weight: bold
				}

				a {
					display: inline-block;
					padding: 5px;
				}

				a:hover {
					color: #409eff;
					background: #f2f2f2;
				}
			}
		}

		.title_center {
			flex: 1;
			height: 100%;
			display: flex;
			text-align: center;

			.carousel-box {
				width: 660px;
				margin: 20px auto 0;
			}

		}

		.title_right {
			width: 240px;
			height: 260px;
			box-sizing: border-box;
			margin-top: 20px;
			padding: 0px 15px;
			background: white;
			border-radius: 8px;

			.form-box {
				width: 100%;
				height: 260px;

				/deep/.el-input__inner {
					height: 30px;
					line-height: 30px;
					border-radius: 15px;
					background-color: rgba(245, 245, 245, 1);
					color: rgba(136, 136, 136, 1);
					font-size: 14px;
					border: none;
				}

				.input-box {
					position: relative;
					margin-top: 10px;

					.verification {
						width: 80px;
						text-align: center;
						height: 30px;
						line-height: 30px;
						border-radius: 15px;
						background-color: rgba(5, 142, 245, 1);
						color: rgba(255, 255, 255, 1);
						font-size: 14px;
						position: absolute;
						right: 0;
						top: 0;
						z-index: 1;
						cursor: pointer;

					}
				}

				ul {
					padding: 0;
					margin: 0;
					list-style: none;
					display: flex;

					li {
						width: 50%;
						line-height: 40px;
						height: 40px;
						color: #898989;
						font-size: 16px;
						cursor: pointer;
						text-align: center;
					}

					li.select-title {
						color: #101010;
						font-weight: bold;
					}
				}

				.checkbox-text {
					font-size: 12px;
					margin-left: 10px;

					a {
						color: rgba(0, 137, 255, 1)
					}
				}

				.btn-box {

					margin: 10px 0;
					position: relative;

					.sign-personal {
						width: 100%;
						height: 30px;
						line-height: 30px;
						border-radius: 15px;
						background-color: rgba(0, 137, 255, 1);
						color: rgba(255, 255, 255, 1);
						font-size: 14px;
						text-align: center;
						font-family: Microsoft Yahei;
						cursor: pointer;
					}

					.signbtn {
						width: 100px;
						height: 30px;
						border-radius: 15px;
						background-color: rgba(0, 137, 255, 1);
						color: rgba(255, 255, 255, 1);
						font-size: 14px;
						text-align: center;
						font-family: Microsoft Yahei;
						border: none;
						cursor: pointer;
					}

					.registerbtn {
						width: 100px;
						height: 30px;
						border-radius: 15px;
						background-color: rgba(15, 179, 123, 1);
						color: rgba(255, 255, 255, 1);
						font-size: 14px;
						text-align: center;
						border: none;
						float: right;
						cursor: pointer;
					}
				}
			}
		}
	}

	.message-box {
		width: 1200px;
		height: 340px;
		margin: 0px auto;

		ul {
			margin: 0;
			margin-top: 20px;
			padding: 0;
			list-style: none;
			width: 100%;
			height: 100%;
			display: flex;

			li {
				width: 378px;
				height: 100%;
				border-radius: 12px;
				margin-right: 33px;
				background-color: rgba(255, 255, 255, 1);
				text-align: center;
				box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
				text-align: left;
				font-size: 15px;
				padding: 0 15px;
				box-sizing: border-box;

				.top-box {
					height: 54px;
					border-bottom: 1px solid rgba(15, 0, 94, 1);
					display: flex;
					align-items: center;

					.left {
						width: 85%;
						line-height: 54px;
						height: 54px;
						display: flex;
						align-items: center;
						position: relative;

						img {
							margin-right: 8px;
						}

						.border {
							width: 123px;
							height: 3px;
							background: rgba(15, 0, 94, 1);
							position: absolute;
							left: 0;
							bottom: -2px;
						}

					}

					.right {
						flex: 1;
						display: flex;
						align-items: center;
						color: #1990FF;
						font-size: 14px;
						cursor: pointer;
					}
				}

				.bottom-box {
					.items {
						display: flex;
						margin: 10px 0;
						cursor: pointer;

						.titles {
							width: 85%;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;

							i {
								width: 5px;
								height: 5px;
								border-radius: 50%;
								display: inline-block;
								background-color: #000;
								margin-right: 5px;
							}

							img {
								height: 20px;
								margin-right: 5px;
							}
						}

						.date {
							text-align: right;
							width: 15%;
						}
					}

					.items:hover {
						color: #058EF5;
					}
				}
			}

			li:last-child {
				margin-right: 0;
			}
		}
	}


	.big-title {
		width: 1200px;
		margin: 0px auto;
		text-align: center;
		color: rgba(15, 0, 94, 1);
		font-size: 32px;
		text-align: center;
		font-family: SourceHanSansSC-bold;
		padding: 30px 0;
	}

	.home-enterprise {
		width: 1200px;
		margin: 0 auto;

		ul {
			width: 100%;
			margin: 0;
			padding: 0;
			overflow: hidden;

			li {
				width: 285px;
				margin-right: 20px;
				margin-bottom: 20px;
				border-radius: 8px;
				overflow: hidden;
				float: left;
				cursor: pointer;

				.recruit-pic {
					width: 100%;
					height: 154px;
				}

				.company-box {
					align-items: center;
					line-height: 43px;
					height: 43px;
					background: white;
					color: rgba(15, 0, 94, 1);
					font-size: 16px;
					text-align: center;
					font-family: SourceHanSansSC-regular;

					img {
						width: 40px;
						height: 40px;
						margin: 0 5px;
					}
				}
			}

			li:nth-child(4n) {
				margin-right: 0 !important;
			}
		}
	}



	.home-advertisement {
		width: 1200px;
		margin: 0px auto;
		margin-top: 5px;

		img {
			padding-top: 5px;
		}

	}

	.home-company {
		width: 1200px;
		margin: 0px auto;
		background: #f8f8f8;
		padding: 15px 0;
		border-radius: 4px;

		.company-content {
			.company-title {
				font-size: 18px;
				color: #333;
				margin-bottom: 16px;

				.more {
					font-size: 14px;
					color: #898989;
					cursor: pointer;
				}

				.name {
					color: rgba(15, 0, 94, 1);
					font-size: 32px;
					text-align: center;
					font-family: SourceHanSansSC-bold;
					text-align: center;
					padding: 40px 0;


				}
			}

			.company-cell {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-around;
				height: auto;

				.company-item {
					cursor: pointer;
					width: 230px;
					height: 135px;
					// padding: 12px 0;
					background: #fff;
					margin-bottom: 15px;
					position: relative;
					overflow: hidden;

					.company-logo {
						height: 50px;
						padding-top: 12px;
						text-align: center;

						img {
							height: 50px;
						}
					}

					.company-name {
						font-size: 14px;
						line-height: 32px;
						color: #333;
						text-align: center;
						width: 70%;
						margin: 0 auto;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}

					.company-num {
						padding: 0px 8px;
						border-radius: 2px;
						border: 1px solid #eee;
						font-size: 12px;
						background: #fff;
						width: 180px;
						margin: 0px auto;
						text-align: center;
						height: 35px;
						line-height: 3;

						b {
							color: #ff0000;
						}
					}

					.company-post {
						position: absolute;
						padding: 16px;
						left: 0;
						right: 0;
						top: 140px;
						height: 110px;
						background: rgba($color: #000000, $alpha: 0.6);
						transition: all 0.5s;

						.post-item {
							display: flex;
							align-items: center;
							justify-content: space-between;
							margin-bottom: 10px;

							.post-name {
								color: #fff;
								font-size: 12px;
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap;
								max-width: 190px;
							}

							.post-salary {
								font-size: 12px;
								color: #ff0000;
								max-width: 50px;
							}
						}

						.post-more {
							text-align: center;
						}
					}

					.hoveractive {
						top: 0;
					}
				}
			}
		}
	}

	.home-position {
		width: 1170px;
		margin: 0px auto;
		background: #fff;
		padding: 15px;
		border-radius: 4px;
		margin-bottom: 20px;

		.position-content {
			.position-title {
				font-size: 18px;
				color: #000;
				margin-bottom: 16px;

				.more {
					font-size: 14px;
					color: #898989;
					cursor: pointer;

				}

				.name {
					color: rgba(15, 0, 94, 1);
					font-size: 32px;
					text-align: center;
					font-family: SourceHanSansSC-bold;
					text-align: center;

				}
			}

			.position-cell {
				display: flex;
				flex-wrap: wrap;
				justify-content: start;
				align-items: stretch;
				overflow: hidden;

				.position-item {
					width: 360px;
					padding: 10px 15px;
					position: relative;
					// background: #f2f2f2;
					// margin-bottom: 10px;
					border-radius: 2px;
					cursor: pointer;
					float: left;

					.position-hd {
						font-size: 14px;
						color: #333;
						display: flex;
						// margin-bottom: 2px;
						align-items: center;
						float: left;
						margin-right: 10px;

						.post-name {
							// font-weight: bold;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
						}

						.post-salary {
							max-width: 70px;
							font-size: 16px;
							text-align: right;
							color: #ff6600;
							font-weight: bold;
						}

						img {
							height: 18px;
							margin-right: 5px;
						}
					}

					.position-bd {
						font-size: 14px;
						color: #0681fc;

						text-align: left;

						.post-info {
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
						}

						.desc {
							height: 30px;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: normal;
						}

						span {
							padding: 0 4px;
							border-right: 1px solid #eee;
						}

						span:first-child {
							padding-left: 0;
						}

						span:last-child {
							border: none;
						}
					}

					.position-card {
						display: flex;
						align-items: center;
						text-align: left;
						padding-top: 12px;
						border-top: 1px dashed #ddd;

						.card-img {
							width: 32px;
							line-height: 32px;
							height: 32px;
							display: flex;
							align-items: center;
							margin-right: 12px;
							background: #fff;
							border-radius: 2px;

							img {
								width: 100%;
							}
						}

						.card-body {
							flex: 1;

							.card-name {
								font-size: 14px;
								word-break: keep-all;
								overflow: hidden;
								text-overflow: ellipsis;
								width: 169px;
								white-space: normal;
								color: #333;
							}

							.card-posthot {
								font-size: 12px;
								color: #787878;

								b {
									color: #409eff;
								}
							}
						}
					}
				}
			}

			.position-more {
				width: 200px;
				height: 32px;
				line-height: 32px;
				color: #fff;
				font-size: 14px;
				margin: 0px auto;
				text-align: center;

				a {
					text-decoration: none;
					color: #fff;
					background: #409eff;
					transition: all 0.5s;
					display: block;
				}

				a:hover {
					background: rgba($color: #409eff, $alpha: 0.7);
				}
			}
		}
	}

	.home-links {
		width: 1170px;
		margin: 0px auto;
		background: #fff;
		padding: 15px;
		border-radius: 4px;
		margin-bottom: 20px;
		padding-bottom: 0;

		.links-content {
			.links-title {
				font-size: 18px;
				color: #000;
				margin-bottom: 16px;
			}

			.links-cell {
				.cell-item {
					font-size: 14px;
					height: 30px;
					padding: 5px;
					position: relative;
					display: inline-block;
				}

				a:hover {
					color: #409eff
				}
			}
		}
	}

	.home-workplace {
		width: 1170px;
		margin: 0px auto;
		background: #fff;
		padding: 0 15px;
		border-radius: 4px;
		margin-bottom: 5px;
		padding-bottom: 0;


		.workplace-comtent {

			.position-title {
				font-size: 18px;
				color: #000;
				margin-bottom: 16px;
				display: flex;

				position: relative;

				.more {
					font-size: 14px;
					color: #898989;
					cursor: pointer;

				}

				.workplace-title {
					margin-right: 24px;
					font-size: 16px;
					margin: 10px 24px 10px 0;
					color: #000;
					font-weight: bold;
				}

				.name {
					overflow: hidden;
					color: rgba(16, 16, 16, 100);
					font-size: 20px;
					font-family: SourceHanSansSC-bold;
					font-weight: bold;

					img {
						width: 24px;
						height: 24px;
						margin-right: 5px;
					}
				}
			}




			.workplace-nav {
				flex: 1;
				display: flex;
				cursor: pointer;
				margin-top: 15px;
				margin-left: 86px;
				justify-content: flex-end;

				.workplace-item {
					margin: 10px 0;
					padding: 0px 10px;
					margin-right: 16px;
					padding-bottom: 12px;
					color: rgba(16, 16, 16, 100);
					font-size: 14px;

				}



				.workplace-nav {
					flex: 1;
					display: flex;
					cursor: pointer;
					margin-top: 15px;
					margin-left: 86px;

					.workplace-item {
						margin: 10px 0;
						padding: 0px 10px;
						margin-right: 16px;
						padding-bottom: 12px;
						color: rgba(16, 16, 16, 100);
						font-size: 14px;
					}

					.active {
						// padding-bottom: 1px;
						// border-bottom: 3px solid #409eff;
						// color: #409eff;
					}


					.workplace-bd {
						margin-top: 9px;

						.workplace-cell {
							display: flex;
							align-items: stretch;
							justify-content: space-between;
							flex-wrap: wrap;

							.workplace-item {
								cursor: pointer;
								width: 490px;
								height: 84px;
								padding: 16px 0;
								display: flex;
								align-items: center;

								.workplace-img {
									width: 140px;
									height: 84px;
									display: flex;
									align-items: center;
									justify-content: center;
									margin-right: 16px;

									img {
										height: 84px;
									}
								}

								.workplace-body {
									flex: 1;
									width: 100%;
									overflow: hidden;
									text-align: left;

									.workplace-name {
										font-size: 14px;
										color: #333;
										font-weight: bold;
										margin-bottom: 8px;
										text-overflow: ellipsis;
										-o-text-overflow: ellipsis;
										-webkit-text-overflow: ellipsis;
										-moz-text-overflow: ellipsis;
										white-space: nowrap;
									}

									.workplace-detail {
										font-size: 12px;
										color: #787878;
										line-height: 20px;
										overflow: hidden;
										word-break: break-all;
										display: -webkit-box;
										-webkit-line-clamp: 3;
										-webkit-box-orient: vertical;
										overflow: hidden;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.personnel {
		// flex: 1;
		display: flex;
		width: 1200px;
		margin: 0px auto;
		padding-bottom: 15px;
		justify-content: space-between;

		overflow: hidden;

		// overflow-x: scroll; // 子元素的宽度大于父元素的即可滚动
		// overflow-y: hidden;
		.personnel_details {
			width: 180px;
			background-color: #fff;
			text-align: center;
			color: rgba(137, 137, 137, 100);
			font-size: 14px;
			padding: 5px;
			cursor: pointer;

			.resume-logo {
				width: 30px;
				height: 30px;
				background: #E7F3FF;
				padding: 10px;
				border-radius: 50%;
				display: inline-block;

				img {
					width: 30px;
					height: 30px;
					border-radius: 50%;
				}
			}

			.name {
				color: rgba(16, 16, 16, 100);
				font-size: 16px;
			}

			.introduce {
				width: 100%;
				display: flex;
				justify-content: center;
				margin-top: 10px;
				font-size: 12px;

				span {
					margin-right: 10px;
				}
			}

			.intention {
				margin-left: 16px;
				display: flex;
				justify-content: flex-start;
				margin-top: 12px;
			}

		}
	}

	.user-nav {
		display: flex;
		justify-content: flex-end;
		align-items: center;

		.user-name {
			font-size: 14px;
			max-width: 120px;
			overflow: hidden;
			text-overflow: ellipsis;
			word-break: keep-all;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		a {
			font-size: 14px;
			text-decoration: none;
			color: #333;
			transition: all 0.5s;
			padding: 0 5px;

			i {
				margin-right: 5px;
			}

			img {
				width: 30px;
				height: 30px;
				border-radius: 15px;
				margin-left: 8px;
			}
		}

		a:hover {
			color: #409eff;
		}

		.follow_wechat {
			font-size: 13px;
			display: flex;
			align-items: center;

		}

		img {
			width: 30px;
			height: 30px;
			margin-right: 6px;
			;
		}

		.img2 {
			width: 16px;
			height: 16px;
			margin-left: 9px;
			margin-top: 2px;
		}
	}

	.user-nav-item1 {
		display: flex;
		align-items: center;

		img {
			cursor: pointer;
			width: 30px;
			height: 30px;
			border-radius: 15px;
			margin-left: 8px;
		}
	}

	.red {
		color: #ff0000;
	}

	.weight {
		font-weight: bold;
	}

	.is-selected {
		color: #1989fa;
	}
</style>
