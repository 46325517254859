import axios from "axios";
import globalconfig from "@/assets/js/globalconfig";

/****获取验证码****/
//mobile 手机号
//type 1 个人 2 企业
export const getmobilecode = params => {
    var url = globalconfig.baseurl + "/apipc/mobilecode";
    return axios.post(url, params, {
        headers: {
            'Content-Type': params.showLoadType
        }
    });
};
/****用户/企业 注册****/
//{mobile:手机号，mobilecode：手机验证码，password：密码，repassword：重复密码，type：1.个人，2.企业}
export const Registeruser = params => {
    var url = globalconfig.baseurl + "/apipc/registeruser";
    return axios.post(url, params, {
        headers: {
            'showLoadType': params.showLoadType
        }
    });
};
/****用户/企业 注册****/
//{mobile:手机号，mobilecode：手机验证码，password：密码，repassword：重复密码，type：1.个人，2.企业}
export const UserLogin = params => {
    var url = globalconfig.baseurl + "/apipcuser/loginuser";
    return axios.post(url, params, {
        headers: {
            'showLoadType': params.showLoadType
        }
    });
};
/****用户/企业 注册****/
//{mobile:手机号，mobilecode：手机验证码，password：密码，repassword：重复密码，type：1.个人，2.企业}
export const CompanyLogin = params => {
    var url = globalconfig.baseurl + "/apipccompany/loginuser";
    return axios.post(url, params, {
        headers: {
            'showLoadType': params.showLoadType
        }
    });
};
/****用户中心：修改优势亮点 {id：用户ID, field：优势亮点}****/
//{mobile:手机号，mobilecode：手机验证码，password：密码，repassword：重复密码，type：1.个人，2.企业}
export const Setoperzwpj = params => {
    var url = globalconfig.baseurl + "/apipcuser/operzwpj";
    return axios.post(url, params, {
        headers: {
            'showLoadType': params.showLoadType
        }
    });
};
/****用户中心：投递过的简历列表 {id：用户ID}****/
export const Getresumelist = params => {
    var url = globalconfig.baseurl + "/apipcuser/resumelist";
    return axios.post(url, params, {
        headers: {
            'showLoadType': params.showLoadType
        }
    });
};
/****用户中心：收藏列表 {id：用户ID}****/
export const Getfavlist = params => {
    var url = globalconfig.baseurl + "/apipcuser/favlist";
    return axios.post(url, params, {
        headers: {
            'showLoadType': params.showLoadType
        }
    });
};
/****创建/更新简历****/
export const Createresumeinfo = params => {
    var url = globalconfig.baseurl + "/apipcuser/createresumeinfo";
    return axios.post(url, params, {
        headers: {
            'showLoadType': 'all'
        }
    });
};
/****修改密码****/
export const EditPassWord = params => {
    var url = globalconfig.baseurl + "/apipcuser/operpassword1";
    return axios.post(url, params, {
        headers: {
            'showLoadType': params.showLoadType
        }
    });
};

/****修改邮箱****/
export const Editemail1 = params => {
    var url = globalconfig.baseurl + "/apipcuser/operemail1";
    return axios.post(url, params, {
        headers: {
            'showLoadType': params.showLoadType
        }
    });
};
/****查看用户简历：{id：简历ID}****/
export const Getresumebyid = params => {
    var url = globalconfig.baseurl + "/apipc/getresumebyid";
    return axios.post(url, params);
};
/****获取扫码信息****/
export const Getscanuserinfo = params => {
    var url = globalconfig.baseurl + "/apipc/getscanuserinfo";
    return axios.post(url, params);
};

/****微信注册用户****/
//mobile 手机号
//name 用户名
//password 密码
//repassword 确认密码
//type 类型 1 个人 2 企业
//unionid 
export const Registerwx = params => {
    var url = globalconfig.baseurl + "/apipc/register";
    return axios.post(url, params);
};
/****老用户绑定微信****/
//mobile 手机号
//password 密码
//type 类型 1 个人 2 企业
//unionid 
export const Wxbinduser = params => {
    var url = globalconfig.baseurl + "/apipc/binduser";
    return axios.post(url, params);
};
/****登录：用户协议、企业协议和个人保护政策****/
export const GetNewsByType = params => {
    var url = globalconfig.baseurl + "/apipc/GetNewsByType";
    return axios.post(url, params);
};

/****招聘会投递岗位****/
export const zphresumelist = params => {
    var url = globalconfig.baseurl + "/apipcuser/zphresumelist";
    return axios.post(url, params);
};

export const getinfolist = params => {
    var url = globalconfig.baseurl + "/apipc/getinfolist";
    return axios.post(url, params);
};

export const addinfo = params => {
    var url = globalconfig.baseurl + "/apipcuser/addinfo";
    return axios.post(url, params);
};

export const getinfo = params => {
    var url = globalconfig.baseurl + "/apipcuser/getinfo";
    return axios.post(url, params);
};

export const delinfo = params => {
    var url = globalconfig.baseurl + "/apipcuser/delinfo";
    return axios.post(url, params);
};


export const getworklist = params => {
    var url = globalconfig.baseurl + "/apipc/getworklist";
    return axios.post(url, params);
};

export const addwork = params => {
    var url = globalconfig.baseurl + "/apipcuser/addwork";
    return axios.post(url, params);
};
export const delwork = params => {
    var url = globalconfig.baseurl + "/apipcuser/delwork";
    return axios.post(url, params);
};
export const GetPerNum = params => {
    var url = globalconfig.baseurl + "/apipcuser/getPerNum";
    return axios.post(url, params, {
        headers: {
            'showLoadType': params.showLoadType
        }
    });
};