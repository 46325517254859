<template>
	<div id="app">
		<router-view />
	</div>
</template>

<script>
	export default {
		name: "App",
	};
</script>

<style>
	html {
		width: 100%;
		height: 100%;
		background: #f8f8f8;
	}
	#app,body{
		height: 100%;
	}
	input {
		outline: none;
		border: none;
	}

	a,
	.botton,.business-t,
	.el-dropdown-link {
		cursor: pointer;
	}

	.flex {
		display: flex;
		justify-content: space-between;
	}

	.flexc {
		display: flex;
		align-items: center;
	}

	.flex1 {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}

	.flex2 {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
	}

	.flex3 {
		display: flex;
		align-items: flex-start;
	}

	.flex4 {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		flex-wrap: wrap;
	}

	.flex5 {
		display: flex;
		justify-content: space-around;
		align-items: center;
		flex-wrap: wrap;
	}

	.clear {
		clear: both;
	}

	.text-tags {
		color: rgba(137, 137, 137, 100);
		font-size: 14px;
	}

	.text-tags span::before {
		white-space: pre;
		content: '   ';
	}

	.text-tags span::after {
		white-space: pre;
		content: '   |';
	}

	.text-tags span:first-child::before {
		content: ''
	}

	.text-tags span:last-child::after {
		content: ''

	}

	.search {
		overflow: hidden;
		background-color: #fff;
		margin-bottom: 10px;
		line-height: 64px;
		padding: 0 15px;
	}
	.search .el-select{width:90px;}
	.search-input {
		background-color: #fff;
		border-radius: 2px;
		border: 1px solid #e6e6e6;
		height: 30px;
		line-height: 30px;
		padding: 0 10px;
		font-size: 12px;
		width: 200px
	}

	.select-div {
		display: inline-block;
	}

	.select-div input,
	.select-div .el-dropdown-link {
		display: inline-block;
		background-color: #fff;
		border-radius: 2px;
		border: 1px solid #e6e6e6;
		height: 30px;
		line-height: 30px;
		padding: 0 10px;
		font-size: 12px;
	}

	.search-ul {
		display: inline;
		margin: 0;
		padding: 0;
	}

	.search-ul li {
		margin-left: 8px;
		float: left;
		list-style: none;
		display: flex;
		align-items: center;
		line-height: 64px;
		height: 64px;
	}

	.search-ul li .layui-text {
		font-size: 12px;
		line-height: 64px;
	}

	.search-ul li .search-input {
		width: 150px;
	}

	.search-ul li .date-input {
		width: 100px;
	}

	.search-ul li .layui-form-mid {
		position: relative;
		padding: 0px 5px !important;
		margin: 0;
		line-height: 30px
	}

	.search-ul li.layui-form-switch {
		margin-top: 0px;
	}

	.search-ul li .layui-btn {}

	.search-ul li .layui-btn-primary {
		border: none;
		line-height: 32px;
		height: 32px;
		padding: 0 20px;
		background-color: #1E9FFF;
		color: white;
		border-radius: 4px;
	}

	.search-ul li .layui-btn-primary:hover {
		color: white;
	}

	.red {
		color: #ff0000;
	}

	.green {
		color: #1f9b29;
	}

	.yellow {
		color: #ffb800;
	}

	.blue {
		color: #276bf2;
	}

	.orgin {
		color: #fb6703;
	}

	.hui {
		color: #999999;
	}
</style>
