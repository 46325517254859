<template>
	<div class="right-tool">
		<a @click="goto('login')" v-if="!userinfo">
			<img src="../assets/img/nav/login.png">
			<span>登录</span>
		</a>
		<a @click="goto('register')" v-if="!userinfo">
			<img src="../assets/img/nav/register.png">
			<span>注册</span>
		</a>
		<a @mousemove="mouseOverCard(2)" @mouseout="mouseOutCard(2)">
			<img src="../assets/img/nav/erweima.png">
			<span>二维码</span>
			<div class="cardbox" v-if="Card3Show">
				<img :src="require('@/assets/img/home/card.png')">
			</div>
		</a>
		<a>
			<img src="../assets/img/nav/Customer.png">
			<span>客服</span>
		</a>
		<a @click="returnTop" v-if="bool">
			<img src="../assets/img/nav/top1.png">
			<span>返回顶部</span>
		</a>
		<a @click="returnTop" v-if="!bool">
			<img src="../assets/img/nav/top.png">
			<span>返回顶部</span>
		</a>
	</div>
</template>

<script>
	import {
		isnull
	} from "@/assets/js/index";
	export default {
		data() {
			return {
				bool: false,
				userinfo: null,
				Card1Show: false,
				Card2Show: false,
				Card3Show: false,
			}
		},
		created() {
			if (!isnull(localStorage.getItem("userinfo"))) {
				this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
			}
			console.log(this.userinfo)
		},
		methods: {
			returnTop() {
				var scrollT =
					document.documentElement.scrollTop || document.body.scrollTop;

				const timeTop = setInterval(() => {
					document.body.scrollTop = document.documentElement.scrollTop = scrollT -= 50
					if (scrollT <= 0)
						clearInterval(timeTop)
				}, 10)
			},
			handleScroll() {
				var ksHeight = document.documentElement.offsetHeight;
				var scrollT =
					document.documentElement.scrollTop || document.body.scrollTop;
				var scrollH = document.body.scrollHeight;
				if (scrollT > 0) {
					this.bool = true;
				} else {
					this.bool = false;
				}
				return this.bool;
			},
			mouseOverCard(type) {
				if (type == 0) {
					this.Card1Show = true
				} else if (type == 1) {
					this.Card2Show = true
				} else {
					this.Card3Show = true
				}
			},
			mouseOutCard(type) {
				if (type == 0) {
					this.Card1Show = false
				} else if (type == 1) {
					this.Card2Show = false
				} else {
					this.Card3Show = false
				}
			},
			goto(url, data, type = 1) {
				this.resolvewin(url, data, type);
			},
		},
		mounted() {
			window.addEventListener("scroll", this.handleScroll);
		},
	}
</script>

<style lang="scss" scoped>
	.right-tool {
		position: fixed;
		width: 80px;
		top: 35%;
		right: calc(50% - 700px);
		background-color: #fff;
		text-align: center;

		.cardbox {
			z-index: 1;
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			top: 96%;
			width: 100px;
			height: 100px;

			img {
				width: 100%;
				height: 100%;
			}
		}

		a {
			display: block;
			border: 1px solid #ddd;
			padding: 15px 0;
			position: relative;
			img {
				width: 24px;
				height: 24px;
			}

			span {
				display: block;
				font-size: 14px;
				color: #898989;
			}
		}
	}

	.up {
		width: 54px;
		height: 54px;
		filter: alpha(Opacity=60);
		-moz-opacity: 0.6;
		opacity: 0.6;
	}

	.up:hover {
		filter: alpha(Opacity=100);
		-moz-opacity: 1;
		opacity: 1;
	}
</style>
