<template>
	<div id="warper">
		<Header></Header>
		<div id="app-contain">
			<router-view />
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import Header from "@/components/header.vue";
	import Footer from "@/components/footer1.vue";
	export default {
		components: {
			Header,
			Footer,
		},
		data() {
			return {};
		},
		methods: {
			// logout() {
			//     window.sessionStorage.clear();
			//     this.$router.push('/login');
			// }
		},
	};
</script>


<style lang="scss" scoped>
	#warper {
		height: 100%;
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		color: #2c3e50;
	}

	#nav {
		padding: 30px;
	}

	#nav a {
		font-weight: bold;
		color: #2c3e50;
	}

	#nav a.router-link-exact-active {
		color: #42b983;
	}

	#app-contain {
		min-height: calc(100vh - 135px);
	}

	.app-contain {
		position: relative;
		width: 100%;
		background: #f2f2f2;
	}
</style>
