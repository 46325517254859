import axios from "axios";
import globalconfig from "@/assets/js/globalconfig";
/****获取验证码****/
//types 手机号
//PageSize 1 个人 2 企业
export const Getnewslist = params => {
	var url = globalconfig.baseurl + "/apipc/newslist";
	return axios.post(url, params, {
		headers: {
			'showLoadType': params.showLoadType
		}
	});
};
/****首页：广告横幅列表****/
export const Getadlist = params => {
	var url = globalconfig.baseurl + "/apipc/adlist";
	return axios.post(url, params);
};
/****首页：图片友情链接列表****/
export const Getadlinklist = params => {
	var url = globalconfig.baseurl + "/apipc/adlinklist";
	return axios.post(url, params);
};
/****首页：底部下拉菜单友情链接列表****/
// id:14:市政府各部门，23:省内人事网站，24:省外人事网站，25:市内人事网站，27:高校链接，28:其他友情链接
export const Getadwordlinklist = params => {
	var url = globalconfig.baseurl + "/apipc/adwordlinklist";
	return axios.post(url, params);
};
/****首页：企业推荐****/
export const getcompanylogo = params => {
	var url = globalconfig.baseurl + "/apipc/getcompanylogo";
	return axios.post(url, params);
};
export const Gethotcompanylist = params => {
	var url = globalconfig.baseurl + "/apipc/hotcompanylist";
	return axios.post(url, params);
};
export const Gettopcompanylist = params => {
	var url = globalconfig.baseurl + "/apipc/topcompanylist";
	return axios.post(url, params);
};
export const Getcompanylist = params => {
	var url = globalconfig.baseurl + "/apipc/companylist";
	return axios.post(url, params);
};
export const GetNation = params => {
	var url = globalconfig.baseurl + "/apipc/getNation";
	return axios.get(url, params);
};
export const Getywlist = params => {
	var url = globalconfig.baseurl + "/apipc/hotywlist";
	return axios.post(url, params);
};
/****首页：岗位推荐****/
export const Gethotposilist = params => {
	var url = globalconfig.baseurl + "/apipc/hotpostlist";
	return axios.post(url, params);
};
/****首页：岗位推荐 分页****/
export const GethotposilistPage = params => {
	var url = globalconfig.baseurl + "/apipc/hotposilistPage";
	return axios.post(url, params);
};
/****岗位列表****/
export const GetOfferList = params => {
	var url = globalconfig.baseurl + "/apipc/getofferlist";
	return axios.post(url, params);
};
/****首页：岗位搜索****/
export const Getindexsearch = params => {
	var url = globalconfig.baseurl + "/apipc/searchwork";
	return axios.post(url, params, {
		headers: {
			'showLoadType': params.showLoadType
		}
	});
};
/****用户中心：投递简历 {id:岗位ID， uid：用户ID}****/
export const Addresume = params => {
	var url = globalconfig.baseurl + "/apipcuser/addresume";
	return axios.post(url, params, {
		headers: {
			'showLoadType': params.showLoadType
		}
	});
};
/****找工作：条件筛选下拉框：{ types：学历,行业,公司规模 } 参数为中文****/
export const Getposilist = params => {
	var url = globalconfig.baseurl + "/apipc/posilist";
	return axios.post(url, params);
};


/****岗位详情{id：岗位ID}****/
export const Getposidetail = params => {
	var url = globalconfig.baseurl + "/apipc/posidetail";
	return axios.post(url, params, {
		headers: {
			'showLoadType': "all"
		}
	});
};
/****企业详情：详情页{id}****/
export const Getcompanydetail = params => {
	var url = globalconfig.baseurl + "/apipc/companydetail";
	return axios.post(url, params, {
		headers: {
			'showLoadType': "all"
		}
	});
};
/****企业详情：公司岗位列表{id：公司ID}****/
export const Getcompanyposilist = params => {
	var url = globalconfig.baseurl + "/apipc/companyposilist";
	return axios.post(url, params, {
		headers: {
			'showLoadType': params.showLoadType
		}
	});
};
/****用户中心：添加收藏 {id：岗位ID，uid：用户ID}****/
export const Addfav = params => {
	var url = globalconfig.baseurl + "/apipcuser/addfav";
	return axios.post(url, params, {
		headers: {
			'showLoadType': "all"
		}
	});
};
/****用户中心：取消收藏 {id：收藏ID}****/
export const Cancelfav = params => {
	var url = globalconfig.baseurl + "/apipcuser/cancelfav";
	return axios.post(url, params, {
		headers: {
			'showLoadType': "all"
		}
	});
};

/****用户中心：添加关注{id：企业ID，uid：用户ID}****/
export const AddCall = params => {
	var url = globalconfig.baseurl + "/apipcuser/addcoll";
	return axios.post(url, params, {
		headers: {
			'showLoadType': "all"
		}
	});
};
/****用户中心：取消关注 {id：关注ID}****/
export const CancelCall = params => {
	var url = globalconfig.baseurl + "/apipcuser/cancelcoll";
	return axios.post(url, params, {
		headers: {
			'showLoadType': "all"
		}
	});
};



/****现场招聘：招聘会详情{id：招聘会ID}****/
export const Getjobfairlist = params => {
	var url = globalconfig.baseurl + "/apipc/jobfairprev";
	return axios.post(url, params, {
		headers: {
			'showLoadType': params.showLoadType
		}
	});
};
/****现场招聘：招聘会详情{id：招聘会ID}****/
export const Getjobfairdetail = params => {
	var url = globalconfig.baseurl + "/apipc/jobfairdetail";
	return axios.post(url, params, {
		headers: {
			'showLoadType': params.showLoadType
		}
	});
};

/****新闻资讯类型列表****/
export const Getnewtypelist = params => {
	var url = globalconfig.baseurl + "/apipc/newtypelist";
	return axios.post(url, params);
};
export const GetHotnewslist = params => {
	var url = globalconfig.baseurl + "/apipc/hotnewslist";
	return axios.post(url, params, {
		headers: {
			'showLoadType': params.showLoadType
		}
	});
};
/****首页：新闻详情  { id：新闻ID }****/
export const Getnewsbyid = params => {
	var url = globalconfig.baseurl + "/apipc/newsbyid";
	return axios.post(url, params, {
		headers: {
			'showLoadType': "all"
		}
	});
};


/****获取首页轮播图****/
export const Getrotationadvlist = params => {
	var url = globalconfig.baseurl + "/apipc/getrotationadvlist";
	return axios.post(url, params);
};

/****底部友情链接类别****/
export const GetTextLinkList = params => {
	var url = globalconfig.baseurl + "/apipc/GetTextLinkList";
	return axios.post(url, params);
};

/****底部友情链接类别数据****/
export const GetAdvLinkByType = params => {
	var url = globalconfig.baseurl + "/apipc/GetAdvLinkByType";
	return axios.post(url, params);
};

/****应届生岗位列表****/
export const FreshOfferList = params => {
	var url = globalconfig.baseurl + "/apipc/FreshOfferList";
	return axios.post(url, params);
};
/****增加访问量****/
export const newsview = params => {
	var url = globalconfig.baseurl + "/apipc/newsview";
	return axios.post(url, params);
};
/****专场招聘会****/
export const specialjobfairlist = params => {
	var url = globalconfig.baseurl + "/apipc/specialjobfairlist";
	return axios.post(url, params);
};
/****专场招聘会公司****/
export const jobfairdetailclist = params => {
	var url = globalconfig.baseurl + "/apipc/jobfairdetailclist";
	return axios.post(url, params);
};
/****专场招聘会公司详情****/
export const jobfaircbycompanyid = params => {
	var url = globalconfig.baseurl + "/apipc/jobfaircbycompanyid";
	return axios.post(url, params);
};
/****推荐人才****/
export const recPerson = params => {
	var url = globalconfig.baseurl + "/apipc/RecPerson";
	return axios.post(url, params);
};
export const getmessagelist = params => {
	var url = globalconfig.baseurl + "/apipc/getmessagelist";
	return axios.post(url, params, {
		headers: {
			'showLoadType': "all"
		}
	});
};
export const getmessageinfo = params => {
	var url = globalconfig.baseurl + "/apipc/getmessageinfo";
	return axios.post(url, params, {
		headers: {
			'showLoadType': "all"
		}
	});
};
export const addmessageinfo = params => {
	var url = globalconfig.baseurl + "/apipc/addmessageinfo";
	return axios.post(url, params, {
		headers: {
			'showLoadType': "all"
		}
	});hidemessageinfo
};
export const hidemessageinfo = params => {
	var url = globalconfig.baseurl + "/apipc/hidemessageinfo";
	return axios.post(url, params, {
		headers: {
			'showLoadType': "all"
		}
	});
};
export const getsearch = params => {
	var url = globalconfig.baseurl + "/apipc/getsearch";
	return axios.post(url, params);
};
export const addsearch = params => {
	var url = globalconfig.baseurl + "/apipc/addsearch";
	return axios.post(url, params);
};
export const GetHyList = params => {
	var url = globalconfig.baseurl + "/apipc/gethylist";
	return axios.post(url, params);
};
export const GetAllHyList = params => {
	var url = globalconfig.baseurl + "/apipc/getallhylist";
	return axios.post(url, params);
};
export const GetPerHyList = params => {
	var url = globalconfig.baseurl + "/apipc/GetPerHyList";
	return axios.post(url, params);
};

/****招考报名****/
export const GetExamList = params => {
	var url = globalconfig.baseurl + "/apipc/GetExamList";
	return axios.post(url, params);
};
export const GetExamModel = params => {
	var url = globalconfig.baseurl + "/apipc/GetExamModel";
	return axios.post(url, params);
};
export const GetExamItemList = params => {
	var url = globalconfig.baseurl + "/apipc/GetExamItemList";
	return axios.post(url, params);
};
export const GetExamSignUpList = params => {
	var url = globalconfig.baseurl + "/apipc/GetExamSignUpList";
	return axios.post(url, params);
};
export const GetExamSignUp = params => {
	var url = globalconfig.baseurl + "/apipc/GetExamSignUp";
	return axios.post(url, params);
};
export const GetExamByPid = params => {
	var url = globalconfig.baseurl + "/apipc/GetExamByPid";
	return axios.post(url, params);
};
export const GetExamAdd = params => {
	var url = globalconfig.baseurl + "/apipc/GetExamAdd";
	return axios.post(url, params);
};
/****招考报名****/



export const getGVerify = id => {
	function GVerify(options) { //创建一个图形验证码对象，接收options对象为参数
		this.options = { //默认options参数值
			id: "", //容器Id
			canvasId: "verifyCanvas", //canvas的ID
			width: "100", //默认canvas宽度
			height: "30", //默认canvas高度
			fontSize: "20", //默认字体大小
			numSize: 4, //默认验证码个数 类型为number
			type: "blend", //图形验证码默认类型blend:数字字母混合类型、number:纯数字、letter:纯字母
			code: ""
		}

		if (Object.prototype.toString.call(options) == "[object Object]") { //判断传入参数类型
			for (var i in options) { //根据传入的参数，修改默认参数值
				this.options[i] = options[i];
			}
		} else {
			this.options.id = options;
		}

		this.options.numArr = "0,1,2,3,4,5,6,7,8,9".split(",");
		this.options.letterArr = getAllLetter();

		this._init();
		this.refresh();
	}

	GVerify.prototype = {
		/**版本号**/
		version: '1.0.0',

		/**初始化方法**/
		_init: function() {
			var con = document.getElementById(this.options.id);
			var canvas = document.createElement("canvas");
			canvas.id = this.options.canvasId;
			canvas.width = this.options.width;
			canvas.height = this.options.height;
			canvas.style.cursor = "pointer";
			canvas.innerHTML = "您的浏览器版本不支持canvas";
			con.appendChild(canvas);
			var parent = this;
			canvas.onclick = function() {
				parent.refresh();
			}
		},

		/**生成验证码**/
		refresh: function() {
			this.options.code = '';
			var canvas = document.getElementById(this.options.canvasId);
			if (canvas.getContext) {
				var ctx = canvas.getContext('2d');
			}
			ctx.textBaseline = "middle";

			ctx.fillStyle = randomColor(180, 240);
			ctx.fillRect(0, 0, this.options.width, this.options.height);

			if (this.options.type == "blend") { //判断验证码类型
				var txtArr = this.options.numArr.concat(this.options.letterArr);
			} else if (this.options.type == "number") {
				var txtArr = this.options.numArr;
			} else {
				var txtArr = this.options.letterArr;
			}

			for (var i = 1; i <= this.options.numSize; i++) {
				var txt = txtArr[randomNum(0, txtArr.length)];
				this.options.code += txt;
				ctx.font = this.options.fontSize + 'px SimHei';
				//ctx.font = randomNum(this.options.height/2, this.options.height) + 'px SimHei'; //随机生成字体大小
				ctx.fillStyle = randomColor(50, 160); //随机生成字体颜色
				ctx.shadowBlur = randomNum(-3, 3);
				ctx.shadowColor = "rgba(0, 0, 0, 0.3)";
				var x = this.options.width / (this.options.numSize + 1) * i;
				var y = this.options.height / 2;
				var deg = randomNum(-30, 30);
				/**设置旋转角度和坐标原点**/
				ctx.translate(x, y);
				ctx.rotate(deg * Math.PI / 180);
				ctx.fillText(txt, 0, 0);
				/**恢复旋转角度和坐标原点**/
				ctx.rotate(-deg * Math.PI / 180);
				ctx.translate(-x, -y);
			}
			/**绘制干扰线**/
			for (var i = 0; i < 4; i++) {
				ctx.strokeStyle = randomColor(40, 180);
				ctx.beginPath();
				ctx.moveTo(randomNum(0, this.options.width / 2), randomNum(0, this.options.height / 2));
				ctx.lineTo(randomNum(0, this.options.width / 2), randomNum(0, this.options.height));
				ctx.stroke();
			}
			/**绘制干扰点**/
			for (var i = 0; i < this.options.width / 4; i++) {
				ctx.fillStyle = randomColor(0, 255);
				ctx.beginPath();
				ctx.arc(randomNum(0, this.options.width), randomNum(0, this.options.height), 1, 0, 2 * Math
					.PI);
				ctx.fill();
			}
		},

		/**验证验证码**/
		validate: function(code) {
			var verifyCode = code.toLowerCase();
			var v_code = this.options.code.toLowerCase();
			if (verifyCode == v_code) {
				return true;
			} else {
				return false;
			}
		}
	}

	/**生成字母数组**/
	function getAllLetter() {
		var letterStr =
			"a,b,c,d,e,f,g,h,j,k,m,n,p,q,r,s,t,u,v,w,x,y,z,A,B,C,D,E,F,G,H,J,K,M,N,P,Q,R,S,T,U,V,W,X,Y,Z";
		return letterStr.split(",");
	}
	/**生成一个随机数**/
	function randomNum(min, max) {
		return Math.floor(Math.random() * (max - min) + min);
	}
	/**生成一个随机色**/
	function randomColor(min, max) {
		var r = randomNum(min, max);
		var g = randomNum(min, max);
		var b = randomNum(min, max);
		return "rgb(" + r + "," + g + "," + b + ")";
	}

	return new GVerify(id);
}
