import Vue from 'vue'
import Router from 'vue-router'


Vue.use(Router)

import routers from "./routers";

const router = new Router({
  mode:'hash',
  routes:routers,
})

export default router


