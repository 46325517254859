<template>
	<div class="header" v-show="show">
		<div class="header-contain">
			<div class="logo" @click="goto('/home', '')">
				<img src="@/assets/img/logo.png" alt="" />
			</div>
			<div class="header-nav">
				<div class="nav-item" @mouseover="activeTab(index)" @mouseout="moveActive"
					@click="changeTab(item.url)" v-for="(item, index) in navList" :key="index">
					{{ item.name }}
				</div>
				<div id="header-blue-block"></div>
			</div>
			<div class="user-nav">
				<div class="follow_wechat" style="margin-left:20px" v-if="userinfo === null">
					<img src="../assets/img/login_user.png">
					<router-link to="/login">
						个人登录
					</router-link>
					<!-- /
					<router-link to="/register">
						注册
					</router-link> -->
				</div>
				<div class="follow_wechat" style="margin-left:20px" v-if="userinfo === null">
					<img src="../assets/img/login_com.png">
					<router-link to="/loginc">
						企业登录
					</router-link>
					<!-- /
					<router-link to="/registerc">
						注册
					</router-link> -->
				</div>
				<div class="user-nav-item user-nav-item1" v-if="userinfo != null">
					<span class="user-name" :title="
				                userinfo.userType + '' === '1'
				                  ? userinfo.peR_NAME
				                    ? userinfo.peR_NAME
				                    : userinfo.peR_ACCOUNT
				                  : userinfo.coM_NAME
				                  ? userinfo.coM_NAME
				                  : userinfo.coM_ACCOUNT
				              ">【{{
				                userinfo.userType + "" === "1"
				                  ? userinfo.peR_NAME
				                    ? userinfo.peR_NAME
				                    : userinfo.peR_ACCOUNT
				                  : userinfo.coM_NAME
				                  ? userinfo.coM_NAME
				                  : userinfo.coM_ACCOUNT
				              }}】</span>
					<router-link :to="`/${
				              userinfo.userType === 1 ? 'usercenter' : 'companycenter'
				            }?type=0`"><span class="blue">{{
				                userinfo.userType + "" === "1"
				                  ? "[个人中心]"
				                  : "[企业中心]"
				              }}</span>
					</router-link>
					<el-dropdown @command="handleCommand">
						<img :src="setcompanylogo(userinfo.peR_IMG_PATH)" alt="" />
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item command='loingout'>退出</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div>
				<el-dropdown>
					<img src="@/assets/img/kf.png">
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item>0576-85159089</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>
			<div class="hot-tel"></div>
			<div class="login-user"></div>
		</div>
	</div>
</template>

<script>
	import {
		isnull
	} from "@/assets/js/index";
	import global from "@/assets/js/globalconfig";
	export default {
		data() {
			return {
				defimgurl: global.baseimgurl,
				navList: [],
				navList1: [{
						name: "首页",
						sort: 1,
						url: "/home",
						icon: require(`@/assets/img/icon/sy.png`),
						iconC: require(`@/assets/img/icon/syc.png`),
					},
					{
						name: "找工作",
						sort: 2,
						url: "/jobhunting",
						icon: require(`@/assets/img/icon/zgz.png`),
						iconC: require(`@/assets/img/icon/zgzc.png`),
					},
					{
						name: "找企业",
						sort: 3,
						url: "/findEnterprise",
						icon: require(`@/assets/img/icon/zrc.png`),
						iconC: require(`@/assets/img/icon/zrcc.png`),
					},
					// 	{
					// 	name: "招考报名",
					// 	sort: 9,
					// 	url: "/zhaokaobm",
					// 	icon: require(`@/assets/img/icon/rcfw.png`),
					// 	iconC: require(`@/assets/img/icon/rcfwc.png`),
					// },
					// {
					// 	name: "公招信息",
					// 	sort: 9,
					// 	url: "/Eservice",
					// 	icon: require(`@/assets/img/icon/rcfw.png`),
					// 	iconC: require(`@/assets/img/icon/rcfwc.png`),
					// },
					{
						name: "招聘会",
						sort: 4,
						url: "/jobfair",
						icon: require(`@/assets/img/icon/xczp.png`),
						iconC: require(`@/assets/img/icon/xczpc.png`),
					},
					{
						name: "关于我们",
						sort: 12,
						url: "/talentService",
						icon: require(`@/assets/img/icon/rcfw.png`),
						iconC: require(`@/assets/img/icon/rcfwc.png`),
					},
				],
				nowIndex: "",
				activeIndex: "",
				userinfo: null,
				show: true,
			};
		},
		watch: {
			"$store.state.userinfo": {
				handler: function(newVal, oldVal) {
					this.userinfo = newVal;
				},
			},
			$route: {
				handler(val, oldval) {
					var flag = false;
					// this.menuList.map((item, index) => {
					// 	if (item.url === val.path) {
					// 		this.nowIndex = index;
					// 		this.activeIndex = this.nowIndex;
					// 		flag = true;
					// 	}
					// });
					if (!flag) {
						this.nowIndex = "";
						this.activeIndex = this.nowIndex;
					}
					this.isjobfairdetail(val.name);
				},
				// 深度观察监听
				deep: true,
			},
		},
		created() {


			this.navList = this.$store.state.navList;
			// this.navList.map((item, index) => {
			// 	if (item.url === this.$route.path) {
			// 		this.nowIndex = index;
			// 		this.activeIndex = this.nowIndex;
			// 	}
			// });

			this.isjobfairdetail(this.$route.name);
			if (!isnull(localStorage.getItem("userinfo"))) {
				this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
			}
		},
		methods: {
			handleCommand(e) {
				if (e == 'loingout')
					this.loingout();
			},

			isjobfairdetail(name) {
				if (name == "jobfairdetail") {
					if (this.userinfo && this.userinfo.userType != 1) {
						this.show = true;
					} else {
						this.show = false;
					}
				} else {
					this.show = true;
				}
			},
			changeTab(url) {
				if (url.indexOf("http") >= 0)
					this.openNewPage(url)
				else
					this.resolvewin(url, {}, 2);
			},
			openNewPage(url) {
				window.open(url, '_blank')
			},
			activeTab(index) {
				this.activeIndex = index;
			},
			moveActive() {
				this.activeIndex = this.nowIndex;
			},
			setcompanylogo(url) {
				return isnull(url) ?
					require("@/assets/img/tx.png") :
					(url.indexOf("https://") >= 0 ? "" : this.defimgurl) + url;
			},

			goto(url, data, type = 1) {
				this.resolvewin(url, data, type);
			},
			// checkRouterLocal(path) {
			//     this.nowIndex = this.navList.findIndex(item => item.url === path);
			// }
			loingout() {
				var _this = this;
				_this
					.$confirm("确定退出吗?", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						localStorage.removeItem("token");
						localStorage.removeItem("userinfo");
						if (this.userinfo.userType != 1) {
							_this.resolvewin("/loginc", {
								type: 2
							}, 1);
						} else {
							_this.resolvewin("/login", {
								type: 2
							}, 1);
						}
						this.userinfo = null;
					})
					.catch(() => {});
			},
		},

		// watch: {
		//     "$route"() {
		//         // 获取当前路径
		//         let path = this.$route.path;
		//         // 检索当前路径
		//         this.checkRouterLocal(path);
		//         console.log(this.$route.path)
		//     }
		// },
	};
</script>

<style lang="scss" scoped>
	.header {
		height: 48px;
		width: 100%;
		padding: 8px 0;
		background: #fff;
		position: relative;
		border-bottom: 1px solid #f2f2f2;

		.header-contain {
			width: 1200px;
			margin: 0px auto;
			position: relative;
			display: flex;
			align-items: center;

			.switch {
				cursor: pointer;
				display: flex;
				align-items: center;
				color: #d81e06;
				position: absolute;
				right: 300px;
				font-size: 12px;

				// margin-right: 20px;
				img {
					width: 16px;
					margin-right: 5px;
				}
			}

			.logo {
				height: 48px;
				width: 160px;
				margin-right: 24px;
				display: inline-block;

				img {
					width: 200px;
					height: 100%;
				}
			}

			.header-nav {

				flex: 1;
				display: flex;
				align-items: center;
				font-size: 16px;
				color: #333;

				.nav-item {
					width: 96px;
					cursor: pointer;
					font-weight: bold;
					text-align: center;
					line-height: 48px;
					position: relative;
				}

				.tab-active {
					color: #409eff;
				}

				.tab-active::before {
					content: "";
					position: absolute;
					width: 60px;
					left: 18px;
					height: 3px;
					bottom: 0;
					background: #409eff;
				}
			}

			.user-nav {
				display: flex;
				justify-content: flex-end;
				align-items: center;
				white-space: nowrap;

				.user-name {
					font-size: 14px;
					max-width: 120px;
					overflow: hidden;
					text-overflow: ellipsis;
					word-break: keep-all;
					text-overflow: ellipsis;
					white-space: nowrap;
				}

				a {
					font-size: 14px;
					text-decoration: none;
					color: #333;
					transition: all 0.5s;
					padding: 0 5px;

					i {
						margin-right: 5px;
					}

					img {
						width: 30px;
						height: 30px;
						border-radius: 15px;
						margin-left: 8px;
					}
				}

				a:hover {
					color: #409eff;
				}

				.follow_wechat {
					font-size: 13px;
					display: flex;
					align-items: center;


				}

				img {
					width: 20px;
					height: 20px;
					margin-right: 6px;
					;
				}

				.img2 {
					width: 16px;
					height: 16px;
					margin-left: 9px;
					margin-top: 2px;
				}
			}
		}
	}

	.user-nav-item1 {
		display: flex;
		align-items: center;

		img {
			cursor: pointer;
			width: 30px;
			height: 30px;
			border-radius: 15px;
			margin-left: 8px;
		}

	}
</style>
