import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
export default new Vuex.Store({
	state: {
		userinfo: {
			userType: 1,
		},
		navList: [{
				name: '首页',
				sort: 1,
				url: "/home"
			}, {
				name: '政企招聘',
				sort: 2,
				url: "/news?id=85"
			}, {
				name: '网络招聘',
				sort: 4,
				url: "/jobhunting"
			}, {
				name: '现场招聘',
				sort: 3,
				url: "/jobfair"
			}, {
				name: '校园招聘',
				sort: 5,
				url: "https://www.zjrc.com/active"
			},
			// {
			// 	name: '招考报名',
			// 	sort: 7,
			// 	url: "/zhaokaobm"
			// },
			{
				name: '人才档案',
				sort: 6,
				url: "/online",
			},
			{
				name: '专业技术',
				sort: 7,
				url: "/news?id=89",
			},
			// {
			//     name: '政策',
			//     sort: 7,
			//     url: "/Eservice",
			// }, 
			{
				name: '关于我们',
				sort: 8,
				url: "/talentService"
			}
		],
	},
	mutations: {},
	actions: {},
	modules: {}
})
